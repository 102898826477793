import React from "react";
import Button from "@mui/material/Button";
import {Typography} from "@mui/material";

function Action({totalItems = 0, cartItemsTotal = 0, handleProceedToCheckout = () => {},
                    isCartEmpty = true, hasLoggedInIdentity = false}) {
    return (
        <>
            <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold'}}>
                Order total ({totalItems} item{totalItems > 1 ? 's' : ''}):
                ${cartItemsTotal.toFixed(2)}
            </Typography>
            <Button
                fullWidth
                variant='contained'
                sx={{mt: 3, mb: 2}}
                onClick={handleProceedToCheckout}
                disabled={isCartEmpty}
            >
                {`${hasLoggedInIdentity ? 'Proceed' : 'Sign In'} to checkout`}
            </Button>
        </>
    )
}

export default Action;