import React, {Fragment} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Divider, Link, ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {Link as RouterLink} from "react-router-dom";
import {OrderItem} from "../../../helpers/interfaces";
import {MAIN_PRODUCT_IMAGE_ORDER} from "../../../helpers/globalConst";
import lodash from "lodash";
import {PRODUCT_IMAGE_BASE_URL} from "../../../helpers/apiRoutes";
import {PRODUCT} from "../../../helpers/routes";

interface ItemProps {
    idx: number,
    orderItem: OrderItem
}

function Item(itemPreps: ItemProps) {
    const {idx, orderItem} = itemPreps;
    const imageLocation = lodash.get(orderItem.product?.productImages?.filter(i => i.order === MAIN_PRODUCT_IMAGE_ORDER), '0.location', '');

    return (
        <Fragment key={idx}><ListItem secondaryAction={<></>}>
            <Grid lg={6} md={6} sm={6} xs={6}>
                <Link color="inherit" underline="none" component={RouterLink} to={`${PRODUCT}/${orderItem.productId}`}>
                    <img src={`${PRODUCT_IMAGE_BASE_URL + imageLocation}`} alt={orderItem.product?.description}
                         width={50} style={{float: 'left', marginRight: 10}}/>
                    <ListItemText primary={orderItem.product?.description}/>
                </Link>
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                {orderItem.qty}
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                ${orderItem.price?.toFixed(2)}
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                ${((orderItem.price || 0) * (orderItem.qty || 0)).toFixed(2)}
            </Grid>
        </ListItem>
            <Divider/>
        </Fragment>
    )
}

export default Item;