import {
    Card,
    CardContent,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    List,
    ListItem,
    styled
} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import Header from "./header";
import Item from "./item";
import Total from "./total";
import PaymentMethodHeader from "./paymentMethodHeader";
import PaymentMethodItem from "./paymentMethodItem";
import {Order, PaymentMethod} from "../../helpers/interfaces";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {getOrderDate} from "../../helpers/helper";

const StyledDialogActions = styled(DialogActions)(() => ({
    '@media print': {
        display: 'none'
    }
}));

interface ViewInvoiceDialogProps {
    show: boolean,
    handleClose: () => void,
    order?: Order,
    paymentMethod?: PaymentMethod
}

function ViewInvoiceDialog(viewInvoiceDialogProps: ViewInvoiceDialogProps) {
    const {show, handleClose, order, paymentMethod} = viewInvoiceDialogProps;
    const orderItems = order?.orderItems || [];
    const handlePrint = () => {
        window.print();
    }

    return (
        <Dialog fullScreen open={show} onClose={handleClose}>
            <DialogTitle>Invoice Details</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                        <Grid lg={12} md={12} sm={12} xs={12}>
                            <Container
                                sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                                <Card variant="outlined" sx={{height: '100%'}}>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom >
                                            Order #{order?.id}
                                        </Typography>
                                        <Typography>
                                            Order placed: {getOrderDate(order?.createdAt || '')}
                                        </Typography>
                                        <List sx={{mt: 5}}>
                                            <ListItem secondaryAction={<></>}>
                                                <Header/>
                                            </ListItem>
                                            <Divider/>
                                            {orderItems.map((orderItem, idx) => (
                                                <Item key={idx} idx={idx} orderItem={orderItem}/>
                                            ))}
                                            <ListItem secondaryAction={<></>}>
                                                <Total orderTotal={order?.total}/>
                                            </ListItem>
                                        </List>
                                        <List sx={{mt: 5}}>
                                            <ListItem secondaryAction={<></>}>
                                                <PaymentMethodHeader/>
                                            </ListItem>
                                            <Divider/>
                                            <PaymentMethodItem paymentMethod={paymentMethod}/>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Container>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <StyledDialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant={'contained'} onClick={handlePrint}>Print</Button>
            </StyledDialogActions>
        </Dialog>
    );
}

export default ViewInvoiceDialog;