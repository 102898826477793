import * as React from 'react';
import {Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {HOME} from "../../helpers/routes";
import {BUSINESS_ENTITY, COMPANY_NAME, COPYRIGHT} from "../../helpers/globalConst";
import PoweredBy from "./PoweredBy";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary">
            {COPYRIGHT}{new Date().getFullYear()}{' '}
            <Link component={RouterLink} to={HOME} color={'inherit'}>
                {`${COMPANY_NAME}, ${BUSINESS_ENTITY}.`}
            </Link>
            {` `}
            <PoweredBy />
        </Typography>
    );
}

export default Copyright;