import * as React from "react";
import {Box, Link} from "@mui/material";
import logo from "../../assets/rkgg-logo-2-2-tb.png";
import {Link as RouterLink} from "react-router-dom";
import {LogoContainer} from "./LogoContainer";
import {SloganTypography} from "./SloganTypography";
import {COMPANY_NAME, SLOGAN} from "../../helpers/globalConst";
import {HOME} from "../../helpers/routes";


function Logo({showSlogan = true, width = '100%', mb = 0}) {
    return (
        <Box sx={{flexGrow: 1}}>
            <LogoContainer sx={{mb: mb}}>
                <Link component={RouterLink} to={HOME}>
                    <img src={logo} alt={COMPANY_NAME} style={{width: width}}/>
                </Link>
                {showSlogan && <SloganTypography variant='h6'>
                    {SLOGAN}
                </SloganTypography>}
            </LogoContainer>
        </Box>
    );
}

export default Logo;