import * as React from "react";
import {Card, CardActions, CardContent, CardMedia, Container, Link, Typography} from "@mui/material";
import {ADD_TO_CART, MAIN_PRODUCT_IMAGE_ORDER} from "../../helpers/globalConst";
import {Product} from "../../helpers/interfaces";
import lodash from "lodash";
import ButtonAddToCart from "../../particles/ButtonAddToCart";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {PRODUCT} from "../../helpers/routes";
import {PRODUCT_IMAGE_BASE_URL} from "../../helpers/apiRoutes";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {addToCart} from "../../app/store/cartSlice";
import {useAddCartItemMutation} from "../../services/cartService";
import Loading from "../Loading";

interface SingleProduct {
    product: Product
}

function ProductCard(product: SingleProduct) {
    const loggedInIdentityState = useAppSelector((state) => state.root.identityReducer.loggedInIdentity);
    const hasLoggedInIdentity = !(lodash.isEmpty(loggedInIdentityState) || lodash.isNil(loggedInIdentityState));
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const prod = product.product;
    const imageLocation = lodash.get(prod.productImages?.filter(i => i.order === MAIN_PRODUCT_IMAGE_ORDER), '0.location', '');
    const price = prod.productItems?.reduce(
        (acc, productItem) => acc + (productItem.price || 0.0), 0.00) || 0.0;
    const [addCartItem, {isLoading: addCartItemLoading}] = useAddCartItemMutation();
    const handleAddToCart = () => {
        dispatch(addToCart({productId: prod.id, qty: 1}));
        if (hasLoggedInIdentity) addCartItem({productId: prod.id, qty: 1}); // TODO cover error case
    };
    const navigateToProd = () => {
        navigate(`${PRODUCT}/${prod.id}`, {state: {product: prod}});
    }

    return (
        <>
            <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}
                       maxWidth="xs">
                <Card sx={{height: '100%'}}>
                    <CardMedia
                        component="img"
                        alt={prod.description}
                        sx={{height: {lg: '70%', md: '70%', sm: '70%', xs: '60%'}, '&:hover': {cursor: 'pointer'}}}
                        image={`${PRODUCT_IMAGE_BASE_URL + imageLocation}`}
                        onClick={navigateToProd}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" sx={{fontSize: {lg: 21, md: 21, sm: 21, xs: 18}}}>
                            <Link color="inherit" underline="none" component={RouterLink} to={`${PRODUCT}/${prod.id}`}
                                  state={{product: prod}}>
                                {prod.description}
                            </Link>
                        </Typography>
                        <Typography variant="h6" color="text.secondary" sx={{fontSize: 20}}>
                            {`$${price.toFixed(2)}`}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{pl: 2}}>
                        <ButtonAddToCart text={ADD_TO_CART} onClick={handleAddToCart}/>
                    </CardActions>
                </Card>
            </Container>
            <Loading show={addCartItemLoading}/>
        </>

    );
}

export default ProductCard;