export const NO_MATCH = '*';
export const NOT_FOUND = '/404'
export const HOME = '/';
export const ABOUT = '/about';
export const SIGN_UP = '/sign-up';
export const SIGN_IN = '/sign-in';
export const PRODUCT = '/product';
export const STORE = '/store';
export const CART = '/cart';
export const CHECKOUT = '/checkout';
export const MAGENTO = '/magento';
export const NETSUITE = '/netsuite';
export const MY_ACCOUNT = '/my-account';
export const ORDER = '/order';
export const DOWNLOADS = '/downloads';
export const PROFILE = '/profile';
export const RESET_PASSWORD = '/reset-password';
export const ACTIVATE_ACCOUNT = '/activate';
export const SUPPORT = '/support';
export const DOCS = '/docs';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const COOKIE = '/cookie-policy'