export const COMPANY_NAME = 'RK Global Group';
export const BUSINESS_ENTITY = 'LLC';
export const COPYRIGHT = 'Copyright © ';
export const SLOGAN = 'SOFTWARE & APP DEVELOPMENT';
export const POWERED = 'Powered by';
export const SCALA_WEB = 'https://scala-lang.org';
export const SCALA_ALT = 'Scala'
export const PLAY_FRAMEWORK_WEB = 'https://www.playframework.com';
export const PLAY_FRAMEWORK_ALT = 'Play Framework';
export const REACT_WEB = 'https://react.dev';
export const REACT_ALT = 'React';

export const ENV_DEVELOPMENT: string = 'development';
export const UNAUTHORIZED: number = 401;
export const CUSTOMER_SUPPORT_EMAIL: string = 'support@rkglobalgroup.com';

export const HEADER_ACCESS_TOKEN_FIELD: string = 'X-Auth-Token';
export const HEADER_REFRESH_TOKEN_FIELD: string = 'X-Auth-Refresh-Token';

export const ACC_SIGN_UP = 'Create account';
export const ACC_SIGN_IN = 'Sign in';
export const ACC_SIGN_OUT = 'Sign out';
export const ACC_MY_ACCOUNT = 'My account';
export const BROWSE_STORE = 'Browse in the store';
export const ADD_TO_CART = 'ADD TO CART';

export const MY_ACCOUNT_DRAWER_WIDTH = 240;

export const ROOT_CATEGORY = 0;
export const PRODUCT_CATEGORIES = {
    '/magento': 1100,
    '/netsuite': 1200
};

export const ROOT_CATEGORY_TITLE = 'Applications';
export const PRODUCT_CATEGORY_TITLE = {
    '/magento': 'Magento 2 Extensions',
    '/netsuite': 'NetSuite Scripts'
};

export const MAIN_PRODUCT_IMAGE_ORDER = 0;
export const STRIPE_KEY =
    "pk_test_51N0ZHyFmzAGeySsZsAcaDL6GMUunA2TW1Mt7tsXxVDur0opTWqdT7gntEIDYncKXQD4Bw2jXPNSh9kSIugMOw88700yYO8Ucws";
    //"pk_live_51N0ZHyFmzAGeySsZNoueJpWHz1nX0GL9OM5oOK2zwJptjV9V6hnDYWBIlzZlYiBE6SCfa0E5fZV3B3ZCCVdM3Y1e00lBOBJGau";
export const CHECKOUT_STEPS = ['Cart', 'Payment details', 'Review your order'];