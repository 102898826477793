import {styled, Typography} from "@mui/material";


export const CategoryTitleTypography = styled(Typography)(({theme}) => ({
    color: '#002e53',
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
        fontSize: '1.2rem'
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.8rem'
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem'
    }
}));