import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

function PaymentMethodHeader({activeStep = 0}) {
    return (
        <Grid lg={12} md={12} sm={12} xs={12}>
            <strong>Payment Method</strong>
        </Grid>
    )
}

export default PaymentMethodHeader;