import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {forgotPasswordSchema} from "../../helpers/helper";
import {useForgotPasswordMutation} from "../../services/identityService";
import {useContext} from "react";
import NotificationContext from "../NotificationContext";
import Loading from "../Loading";
import {FORGOT_PASSWORD_EMAIL_FAILED, FORGOT_PASSWORD_EMAIL_SENT} from "../../helpers/notifications";

function ForgotPasswordDialog({show = false, handleClose = () => {}}) {
    const notificationCtx = useContext(NotificationContext);
    const {register, handleSubmit,
        formState: {errors}} = useForm({resolver: yupResolver(forgotPasswordSchema)});
    const [forgotPassword, {isLoading}] = useForgotPasswordMutation();
    const onSubmit = (data: any) => {
        forgotPassword(data)
            .unwrap()
            .then(
                () => {
                    notificationCtx.showNotification(FORGOT_PASSWORD_EMAIL_SENT, 'success');
                    handleClose();
                }
            )
            .catch(
                () => {
                    notificationCtx.showNotification(FORGOT_PASSWORD_EMAIL_FAILED, 'error');
                    handleClose();
                }
            );

    }

    return (
        <Dialog open={show} onClose={handleClose}>
            <Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Forgot password?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the email address associated with your account. We
                        will send you an email with instructions how to reset the password.
                    </DialogContentText>
                    <TextField
                        {...register('email', {required: true})}
                        autoFocus
                        margin='dense'
                        required
                        id='email'
                        label='Email Address'
                        autoComplete='email'
                        type='email'
                        fullWidth
                        variant='filled'
                        error={!!errors.email}
                        helperText={errors.email?.message}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type={'submit'} variant={'contained'}>Submit</Button>
                </DialogActions>
            </Box>
            <Loading show={isLoading}/>
        </Dialog>
    );
}

export default ForgotPasswordDialog;