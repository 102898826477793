import * as React from "react";
import {Card, CardActions, CardContent, CardMedia, Container, Typography} from "@mui/material";
import magentoBanner from "../../assets/magento-adobe.png";
import {MAGENTO, STORE} from "../../helpers/routes";
import ButtonLinkRightArrow from "../../particles/ButtonLinkRightArrow";
import {BROWSE_STORE} from "../../helpers/globalConst";
import {useNavigate} from "react-router-dom";

function MagentoCard() {
    const navigate = useNavigate();
    const navigateToStore = () => {
        navigate(STORE + MAGENTO);
    }

    return (
        <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}} maxWidth="xs">
            <Card sx={{height: '100%'}}>
                <CardMedia
                    sx={{height: {lg: '70%', md: '70%', sm: '70%', xs: '60%'}, '&:hover': {cursor: 'pointer'}}}
                    image={magentoBanner}
                    onClick={navigateToStore}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5"
                                sx={{fontSize: {lg: 21, md: 21, sm: 21, xs: 18}, '&:hover': {cursor: 'pointer'}}}
                                onClick={navigateToStore}>
                        Adobe Commerce (Magento)
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{fontSize: 18, '&:hover': {cursor: 'pointer'}}}
                                onClick={navigateToStore}>
                        Extensions
                    </Typography>
                </CardContent>
                <CardActions sx={{pl: 1.5}}>
                    <ButtonLinkRightArrow text={BROWSE_STORE} to={STORE + MAGENTO}/>
                </CardActions>
            </Card>
        </Container>
    );
}

export default MagentoCard;