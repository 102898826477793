import lodash from "lodash";
import {notifications} from "./notifications";
import * as yup from "yup";
import {
    HEADER_ACCESS_TOKEN_FIELD,
    HEADER_REFRESH_TOKEN_FIELD,
    PRODUCT_CATEGORIES,
    PRODUCT_CATEGORY_TITLE,
    ROOT_CATEGORY,
    ROOT_CATEGORY_TITLE
} from "./globalConst";
import {ApiResponse, CartItem, Identity, Product, SuccessContent} from "./interfaces";
import {MAGENTO, NETSUITE} from "./routes";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const setHeaderAccessToken = (headers: Headers): Headers => {
    const accessToken = localStorage.getItem('accessToken');
    if (!lodash.isNull(accessToken) && !lodash.isEmpty(accessToken)) {
        headers.set(HEADER_ACCESS_TOKEN_FIELD, accessToken);
    }

    return headers;
}

export const setHeaderRefreshToken = (headers: Headers): Headers => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!lodash.isNull(refreshToken) && !lodash.isEmpty(refreshToken)) {
        headers.set(HEADER_REFRESH_TOKEN_FIELD, refreshToken);
    }

    return headers;
}

export const saveAccessToken = (headers: Headers): void => {
    const accessToken = headers.get(HEADER_ACCESS_TOKEN_FIELD);
    if (!lodash.isNull(accessToken) && !lodash.isEmpty(accessToken)) {
        localStorage.setItem('accessToken', accessToken)
    }
}

export const saveRefreshToken = (headers: Headers): void => {
    const refreshToken = headers.get(HEADER_REFRESH_TOKEN_FIELD);
    if (!lodash.isNull(refreshToken) && !lodash.isEmpty(refreshToken)) {
        localStorage.setItem('refreshToken', refreshToken)
    }
}

export const deleteTokens = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
}

export const getIdentityData = (response: ApiResponse) => {
    const content = lodash.get(response, 'content');
    if (lodash.isUndefined(content)) {
        return;
    }

    const data = lodash.get(content as SuccessContent, 'data')
    if (lodash.isUndefined(data)) {
        return;
    }

    return lodash.get(data as Identity, 'identity');
}

export const errorMessage = (error: any): string => {
    if (error !== undefined) {
        return lodash.get(error, 'data.content.error', '');
    }

    return '';
}

export const errorNotification = (error: any): string => {
    return lodash.get(notifications, errorMessage(error), 'Internal error');
}

export const getInitials = (firstName?: string, lastName?: string) => {
    return (!lodash.isUndefined(firstName) ? firstName[0] : '') + (!lodash.isUndefined(lastName) ? lastName[0] : '');
}

export const getCategoryByLocation = (pathname: string) => {
    if (pathname.includes(MAGENTO)) return PRODUCT_CATEGORIES[MAGENTO];
    if (pathname.includes(NETSUITE)) return PRODUCT_CATEGORIES[NETSUITE];
    return ROOT_CATEGORY;
}

export const getCategoryTitleByLocation = (pathname: string) => {
    if (pathname.includes(MAGENTO)) return PRODUCT_CATEGORY_TITLE[MAGENTO];
    if (pathname.includes(NETSUITE)) return PRODUCT_CATEGORY_TITLE[NETSUITE];
    return ROOT_CATEGORY_TITLE;
}

export const getProductPrice = (cartItem: CartItem, maybeProducts?: Product[]) => {
    return maybeProducts?.find(product => product.id === cartItem.productId)?.productItems?.reduce(
        (acc, productItem) => acc + (productItem.price || 0.0), 0.00) || 0.0;
}

export const getCartItemsTotal = (cartItems: CartItem[], maybeProducts?: Product[]) => {
    return cartItems.reduce(
        (acc, cartItem) => acc + getProductPrice(cartItem, maybeProducts) * (cartItem.qty || 0), 0.0
    )
}

export const getTotalItems = (cartItems: CartItem[]) => {
    return cartItems.reduce(
        (acc, cartItem) => acc + (cartItem.qty || 0), 0.0
    )
}

export const getDate = (date: string, format = 'MM/DD/YYYY') => {
    let localDate = dayjs.utc(date);
    try {
        localDate = dayjs.utc(date).tz(dayjs.tz.guess());
    } catch (error) {
        console.log(error)
    }

    return localDate.format(format);
}

export const getOrderDate = (createdAt: string) => {
    return getDate(createdAt);
}

export const signUpSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Please enter a valid email address.').required('Email is required'),
    password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters')
}).required();

export const signInSchema = yup.object({
    email: yup.string().email('Please enter a valid email address.').required('Email is required'),
    password: yup.string().required('Password is required')
}).required();

export const forgotPasswordSchema = yup.object({
    email: yup.string().email('Please enter a valid email address.').required('Email is required')
}).required();

export const customerNotActiveSchema = yup.object({
    email: yup.string().email('Please enter a valid email address.').required('Email is required')
}).required();

export const resetPasswordSchema = yup.object({
    newPassword: yup.string().required('New password is required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: yup.string().required('Password confirmation is required')
        .oneOf([yup.ref('newPassword'), ''], 'Passwords must match')
}).required();

export const customerSupportSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Please enter a valid email address.').required('Email is required'),
    message: yup.string().required('Message is required').max(500)
}).required();

export const profileSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    company: yup.string(),
    phone: yup.string(),
    email: yup.string(),
    subscribed: yup.boolean(),
    currentPassword: yup.string().required('Current password is required'),
    newPassword: yup.string().min(8, 'Password must be at least 8 characters')
        .notOneOf([yup.ref('currentPassword'), ''], 'New password cannot be the same as current password'),
    confirmPassword: yup.string().min(8, 'Password must be at least 8 characters')
        .oneOf([yup.ref('newPassword'), ''], 'Passwords must match')
}).required();