import * as React from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import lodash from "lodash";
import {useGetProductsQuery} from "../../services/productService";
import Grid from "@mui/material/Unstable_Grid2";
import {Card, CardContent, Container, Divider, List, ListItem} from "@mui/material";
import {CartItem} from "../../helpers/interfaces";
import Loading from "../Loading";
import {removeFromCart, updateQty} from "../../app/store/cartSlice";
import {useRemoveCartItemMutation, useUpdateCartItemQtyMutation} from "../../services/cartService";
import Header from "./header";
import Item from "./item";
import Total from "./total";
import Action from "./action";
import {getCartItemsTotal, getProductPrice, getTotalItems} from "../../helpers/helper";
import Typography from "@mui/material/Typography";
import {MAIN_PRODUCT_IMAGE_ORDER} from "../../helpers/globalConst";
import {useNavigate} from "react-router-dom";
import {SIGN_IN} from "../../helpers/routes";

function Cart({setStep = (step: number) => {}}) {
    const navigate = useNavigate();
    const loggedInIdentityState = useAppSelector((state) => state.root.identityReducer.loggedInIdentity);
    const hasLoggedInIdentity = !(lodash.isEmpty(loggedInIdentityState) || lodash.isNil(loggedInIdentityState));
    const dispatch = useAppDispatch();
    const {data: maybeProducts, isLoading: getProductsLoading} = useGetProductsQuery({});
    const [updateCartItemQty, {isLoading: updateCartItemQtyLoading}] = useUpdateCartItemQtyMutation();
    const [removeCartItem, {isLoading: removeCartItemLoading}] = useRemoveCartItemMutation();
    const cartContentState = useAppSelector((state) => state.root.cartReducer.cartContent);
    const cartItems = lodash.get(cartContentState, 'cartItems') || [];
    const isCartEmpty = !cartItems.length

    const handleRemoveCartItem = (cartItem: CartItem) => {
        dispatch(removeFromCart(cartItem));
        if (hasLoggedInIdentity) removeCartItem(cartItem); // TODO cover error case
    }

    const handleUpdateCartItemQty = (cartItem: CartItem, qty: number) => {
        const payload = lodash.cloneDeep(cartItem);
        payload.qty = qty;
        dispatch(updateQty(payload));
        if (hasLoggedInIdentity) updateCartItemQty(payload); // TODO cover error case
    }

    const handleProceedToCheckout = () => {
        if (hasLoggedInIdentity) {
            setStep(1)
        } else {
            navigate(SIGN_IN);
        }
    }

    const cartItemProductId = (cartItem: CartItem) => {
        return maybeProducts?.find(product => product.id === cartItem.productId)?.id
    }

    const cartItemDescription = (cartItem: CartItem) => {
        return maybeProducts?.find(product => product.id === cartItem.productId)?.description
    }

    const cartItemImageLocation = (cartItem: CartItem) => {
        return lodash.get(maybeProducts?.find(product => product.id === cartItem.productId)?.productImages?.filter(i => i.order === MAIN_PRODUCT_IMAGE_ORDER), '0.location', '');
    }

    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid lg={8} md={8} sm={12} xs={12}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            {isCartEmpty && <CardContent>
                                <Typography variant="h5" gutterBottom sx={{fontWeight: 'bold'}}>
                                    Shopping Cart is empty
                                </Typography></CardContent>}
                            {!isCartEmpty && <CardContent>
                                <Typography variant="h5" gutterBottom sx={{fontWeight: 'bold'}}>
                                    Shopping Cart
                                </Typography>
                                <List sx={{mt: 5}}>
                                    <ListItem secondaryAction={<></>}>
                                        <Header/>
                                    </ListItem>
                                    <Divider/>
                                    {cartItems.map((cartItem, idx) => (
                                        <Item key={idx} idx={idx} cartItem={cartItem}
                                                     productId={cartItemProductId(cartItem)}
                                                     description={cartItemDescription(cartItem)}
                                                     imageLocation={cartItemImageLocation(cartItem)}
                                                     handleRemoveCartItem={handleRemoveCartItem}
                                                     handleUpdateCartItemQty={handleUpdateCartItemQty}
                                                     productPrice={getProductPrice(cartItem, maybeProducts)}/>
                                    ))}
                                    <ListItem secondaryAction={<></>}>
                                        <Total cartItemsTotal={getCartItemsTotal(cartItems, maybeProducts)}/>
                                    </ListItem>
                                </List>
                            </CardContent>}
                        </Card>
                    </Container>
                </Grid>
                <Grid lg={4} md={4} sm={12} xs={12}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%', maxHeight: {lg: 250, md: 250, sm: 250, xs: 250}}}>
                            <CardContent>
                                <Action totalItems={getTotalItems(cartItems)}
                                        cartItemsTotal={getCartItemsTotal(cartItems, maybeProducts)}
                                        handleProceedToCheckout={() => handleProceedToCheckout()}
                                        isCartEmpty={isCartEmpty} hasLoggedInIdentity={hasLoggedInIdentity}/>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            <Loading show={getProductsLoading || updateCartItemQtyLoading || removeCartItemLoading}/>
        </>
    );
}

export default Cart;