import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {forwardRef, useContext} from "react";
import NotificationContext from "../NotificationContext";
import Slide, {SlideProps} from "@mui/material/Slide";

type TransitionProps = Omit<SlideProps, 'direction'>;
function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notification() {
    const notificationCtx = useContext(NotificationContext);

    return (
        <Snackbar
            open={notificationCtx.open}
            autoHideDuration={6000}
            onClose={notificationCtx.onClose}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            TransitionComponent={TransitionLeft}
        >
            <Alert onClose={notificationCtx.onClose} severity={notificationCtx.severity} sx={{width: '100%'}}>
                {notificationCtx.msg}
            </Alert>
        </Snackbar>
    );
}