import * as React from "react";
import {useContext} from "react";
import {Box, Card, CardContent, Container, styled, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";
import {useSendSupportRequestMutation} from "../../services/identityService";
import NotificationContext from "../NotificationContext";
import Notification from "../Notification";
import Loading from "../Loading";
import TextField from "@mui/material/TextField";
import {yupResolver} from "@hookform/resolvers/yup";
import {customerSupportSchema} from "../../helpers/helper";
import {SEND_CUST_SUPPORT_REQUEST_FAILED, SEND_CUST_SUPPORT_REQUEST_SUCCESS} from "../../helpers/notifications";
import QuizIcon from "@mui/icons-material/Quiz";

const StyledQuizIcon = styled(QuizIcon)(({theme}) => ({
    color: theme.palette.secondary.main,
    fontSize: 'xxx-large'
}));

function CustomerSupportCard() {
    const notificationCtx = useContext(NotificationContext);
    const {
        register, handleSubmit, formState: {errors}
    } = useForm({resolver: yupResolver(customerSupportSchema)});
    const [sendSupportRequest, {isLoading}] = useSendSupportRequestMutation();

    const onSubmit = async (data: any) => {
        sendSupportRequest(data).unwrap().then(() => {
            notificationCtx.showNotification(SEND_CUST_SUPPORT_REQUEST_SUCCESS, 'success')
        })
            .catch(() => {
                    notificationCtx.showNotification(SEND_CUST_SUPPORT_REQUEST_FAILED, 'error')
                }
            );
    }

    return (
        <>
            <Container sx={{pt: 2, pb: 3}} maxWidth='xs'>
                <Card variant="outlined">
                    <CardContent>
                        <Container component='div' sx={{display: 'flex', justifyContent: 'center'}}>
                            <StyledQuizIcon/>
                        </Container>
                        <Typography component='h1' variant='h5' sx={{textAlign: 'center'}}>
                            Your Support Center
                        </Typography>
                        <Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid xs={12} sm={6}>
                                    <TextField
                                        {...register('firstName', {required: true})}
                                        autoComplete='given-name'
                                        required
                                        fullWidth
                                        id='firstName'
                                        label='First Name'
                                        autoFocus
                                        error={!!errors.firstName}
                                        variant='filled'
                                        helperText={errors.firstName?.message}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6}>
                                    <TextField
                                        {...register('lastName', {required: true})}
                                        required
                                        fullWidth
                                        id='lastName'
                                        label='Last Name'
                                        autoComplete='family-name'
                                        error={!!errors.lastName}
                                        variant='filled'
                                        helperText={errors.lastName?.message}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        {...register('email', {required: true})}
                                        required
                                        fullWidth
                                        id='email'
                                        label='Email Address'
                                        autoComplete='email'
                                        autoFocus
                                        error={!!errors.email}
                                        variant='filled'
                                        helperText={errors.email?.message}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        {...register('message', {required: true})}
                                        required
                                        fullWidth
                                        id='message'
                                        label='Your message'
                                        autoFocus
                                        error={!!errors.message}
                                        variant='filled'
                                        helperText={errors.message?.message}
                                        multiline
                                        rows={6}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{mt: 3, mb: 2}}
                            >
                                Submit
                            </Button>
                            <Grid container justifyContent='flex-start' sx={{mt: 1}}>
                                <Grid>
                                    <Typography component={'p'} sx={{fontSize: 14}}>
                                        Customer support: support@rkglobalgroup.com
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {notificationCtx.open && <Notification/>}
            <Loading show={isLoading}/>
        </>
    );
}

export default CustomerSupportCard;