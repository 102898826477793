import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

function Header({activeStep = 0}) {
    return (
        <>
            <Grid lg={6} md={6} sm={6} xs={6}>
                DESCRIPTION
            </Grid>
        </>
    )
}

export default Header;