import * as React from "react";
import {Badge, Button, styled, Typography} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Link as RouterLink} from "react-router-dom";
import {CART, CHECKOUT, STORE} from "../../helpers/routes";
import {useAppSelector} from "../../app/hooks";
import lodash from "lodash";

const CartTypography = styled(Typography)(({theme}) => ({
    [theme.breakpoints.up('xs')]: {
        display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    },
    [theme.breakpoints.up('md')]: {
        display: 'inline',
        fontSize: 14
    }
}));

const StyledCartIcon = styled(ShoppingCartIcon)(({theme}) => ({
    marginLeft: 10,
    [theme.breakpoints.up('xs')]: {
        fontSize: 25
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 30
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 30
    }
}));

function CartButton() {
    const cartContentState = useAppSelector((state) => state.root.cartReducer.cartContent)
    const getCartItemsQty = () => {
        const cartItems = lodash.get(cartContentState, 'cartItems') || [];
        return cartItems.reduce(
            (acc, cartItem) => acc + lodash.get(cartItem, 'qty', 0),
            0
        );
    }

    return (
        <Button color="inherit" component={RouterLink} to={STORE + CART + CHECKOUT}>
            <CartTypography>
                Cart
            </CartTypography>
            <Badge badgeContent={getCartItemsQty()} color="secondary">
                <StyledCartIcon/>
            </Badge>
        </Button>
    );
}

export default CartButton;