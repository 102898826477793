import {Container, styled} from "@mui/material";


export const LogoContainer = styled(Container)(({theme}) => ({
    margin: 0,
    marginTop: 20,
    padding: 0,
    [theme.breakpoints.up('xs')]: {
        width: 200
    },
    [theme.breakpoints.up('sm')]: {
        width: 400
    },
    [theme.breakpoints.up('md')]: {
        width: 500
    }
}));