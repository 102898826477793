import React, {createContext, SyntheticEvent, useState} from "react";
import {AlertColor} from "@mui/material/Alert/Alert";

const NotificationContext = createContext<{
    severity: AlertColor,
    msg: string,
    open: boolean,
    showNotification: (msg: string, severity: AlertColor) => void,
    onClose: () => void
}>({
    severity: 'info',
    msg: '',
    open: false,
    showNotification: () => {},
    onClose: () => {},
});
export default NotificationContext;

export const NotificationContextProvider = (props: {
    children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {
    const [msg, setMsg] = useState('');
    const [severity, setSeverity] = useState<AlertColor>('info')
    const [open, setOpen] = useState(false);
    const handleShowNotification = (msg: string, severity: AlertColor) => {
        setSeverity(severity);
        setMsg(msg);
        setOpen(true);
    };
    const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };
    const value = {
        severity,
        msg,
        open,
        showNotification: handleShowNotification,
        onClose: handleClose,
    }

    return (<NotificationContext.Provider value={value}>{props.children}</NotificationContext.Provider>);
};