import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Card, CardContent, Container, Divider, styled, Typography} from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DescriptionIcon from '@mui/icons-material/Description';
import QuizIcon from '@mui/icons-material/Quiz';
import InfoIcon from '@mui/icons-material/Info';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import PolicyIcon from '@mui/icons-material/Policy';
import PaymentMethodsImg from '../../assets/payment_methods.png'
import {Link as RouterLink} from "react-router-dom";
import {ABOUT, DOCS, MY_ACCOUNT, PRIVACY, SUPPORT, TERMS} from "../../helpers/routes";

const StyledManageAccountsIcon = styled(ManageAccountsIcon)(({theme}) => ({
    color: theme.palette.secondary.main,
    fontSize: 'xx-large'
}));

const StyledDescriptionIcon = styled(DescriptionIcon)(({theme}) => ({
    color: theme.palette.secondary.main,
    fontSize: 'xx-large'
}));

const StyledQuizIcon = styled(QuizIcon)(({theme}) => ({
    color: theme.palette.secondary.main,
    fontSize: 'xx-large'
}));

const StyledInfoIcon = styled(InfoIcon)(({theme}) => ({
    color: theme.palette.secondary.main,
    fontSize: 'xx-large'
}));

const StyledPrivacyTipIcon = styled(PrivacyTipIcon)(({theme}) => ({
    color: theme.palette.secondary.main,
    fontSize: 'xx-large'
}));

const StyledPolicyIcon = styled(PolicyIcon)(({theme}) => ({
    color: theme.palette.secondary.main,
    fontSize: 'xx-large'
}));

function ActionList() {
    return (
        <>
            <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}
                       maxWidth="xs">
                <Card variant='outlined' sx={{height: '100%'}}>
                    <CardContent>
                        <Typography component='h1' variant='h5' sx={{textAlign: 'center', color: '#002e53'}}>
                            Resources
                        </Typography>
                        <Box component='div' sx={{mt: 3}}>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton component={RouterLink} to={MY_ACCOUNT}>
                                        <ListItemIcon>
                                            <StyledManageAccountsIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="My Account"/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton component={RouterLink} to={DOCS}>
                                        <ListItemIcon>
                                            <StyledDescriptionIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Docs & User Guides"/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton component={RouterLink} to={SUPPORT}>
                                        <ListItemIcon>
                                            <StyledQuizIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Customer Support"/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton component={RouterLink} to={ABOUT}>
                                        <ListItemIcon>
                                            <StyledInfoIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="About Us"/>
                                    </ListItemButton>
                                </ListItem>
                                <Divider/>
                                <ListItem disablePadding>
                                    <ListItemButton component={RouterLink} to={TERMS}>
                                        <ListItemIcon>
                                            <StyledPolicyIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Terms & Conditions"/>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton component={RouterLink} to={PRIVACY}>
                                        <ListItemIcon>
                                            <StyledPrivacyTipIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Privacy Policy"/>
                                    </ListItemButton>
                                </ListItem>
                                <img src={PaymentMethodsImg} width={'90%'} alt={'Available payment methods'}
                                     style={{marginLeft: 20, marginTop: 50}}/>
                            </List>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    );
}

export default ActionList;