import * as React from "react";
import {Link as RouterLink} from "react-router-dom";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import Button from "@mui/material/Button";

function ButtonLinkRightArrow({text = '', to = ''}) {
    return (
        <Button size="small" component={RouterLink} to={to} endIcon={<ArrowCircleRightOutlinedIcon/>}>{text}</Button>
    );
}

export default ButtonLinkRightArrow;