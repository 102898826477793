interface Notifications {
    [key: string]: string;
}
export const notifications: Notifications = {
    'user_already_exists': 'An account with the email address you entered already exists'
}

export const SIGN_IN_FAILED = 'You have entered an invalid email address or password. Please try again.';
export const ACCOUNT_ACTIVATION_LINK_INVALID = 'The account activation link has expired or is invalid. Please try again.';
export const ACCOUNT_ACTIVATION_SUCCESS = 'The account has been successfully activated.';
export const FORGOT_PASSWORD_EMAIL_SENT = 'An email with instructions how to reset your password has been sent.';
export const FORGOT_PASSWORD_EMAIL_FAILED = 'Sorry, the email address you entered does not match our records. Please try again.';
export const PASSWORD_RESET_LINK_INVALID = 'The reset password link has expired or is invalid. Please try again.';
export const PASSWORD_RESET_FAILED = 'The reset password link has expired or is invalid. Please try again.';
export const PASSWORD_RESET_SUCCESS = 'The password has been successfully reset. You can sign in mow.';
export const CUSTOMER_ACTIVATION_EMAIL_SENT = 'The activation email has been sent.';
export const CUSTOMER_ACTIVATION_EMAIL_FAILED = 'Sorry, the email address does not match our records. Please try again.';
export const FILE_NOT_FOUND = 'File not found.';
export const SEND_CUST_SUPPORT_REQUEST_FAILED = 'Sending message to customer support failed. Please try again.'
export const SEND_CUST_SUPPORT_REQUEST_SUCCESS = 'Your message has been sent successfully! We\'ll be reaching out to you shortly.'
export const UPDATE_PROFILE_SUCCESS = "Profile settings has been updated successfully";
export const UPDATE_PASSWORD_SUCCESS = "Password has been updated successfully";
export const UPDATE_PROFILE_FAILED = "Updating profile failed";
export const WRONG_CURRENT_PASSWORD = "Wrong current password";