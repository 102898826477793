import React from 'react';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import {Box, Container, CssBaseline, ThemeProvider} from "@mui/material";
import {NotificationContextProvider} from "./components/NotificationContext";
import {LoggedInIdentityContextProvider} from "./components/LoggedInIdentityContext";
import TopNav from "./containers/TopNav";
import Footer from "./containers/Footer";
import {theme} from "./app/theme";
import {globalStyles} from "./app/globalStyles";
import AppRoutes from "./components/AppRoutes";


const boxStyles = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            {globalStyles}
            <BrowserRouter>
                <NotificationContextProvider>
                    <LoggedInIdentityContextProvider>
                        <Box sx={boxStyles}>
                            <CssBaseline/>
                            <TopNav/>
                            <Container component="main" maxWidth="xl" sx={{mt: {lg: 10, md: 10, sm: 5, xs: 2}}}>
                                <AppRoutes/>
                            </Container>
                            <Footer/>
                        </Box>
                    </LoggedInIdentityContextProvider>
                </NotificationContextProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
