import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

function Header({activeStep = 0}) {
    return (
        <>
            <Grid lg={6} md={6} sm={6} xs={6}>
                <strong>Product</strong>
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                <strong>Qty</strong>
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                <strong>Price</strong>
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                <strong>Subtotal</strong>
            </Grid>
        </>
    )
}

export default Header;