import {Route, Routes} from "react-router-dom";
import {
    ABOUT,
    ACTIVATE_ACCOUNT,
    CART,
    CHECKOUT, COOKIE, DOCS, DOWNLOADS,
    HOME,
    MY_ACCOUNT,
    NO_MATCH,
    NOT_FOUND, ORDER, PRIVACY,
    PRODUCT, PROFILE,
    RESET_PASSWORD,
    SIGN_IN,
    SIGN_UP,
    STORE, SUPPORT, TERMS
} from "../../helpers/routes";
import Home from "../../pages/Home";
import About from "../../pages/About";
import SignUp from "../../pages/SignUp";
import SignIn from "../../pages/SignIn";
import NoMatch from "../../pages/NoMatch";
import React from "react";
import MyAccount from "../../pages/MyAccount";
import ProtectedRoute from "../../containers/ProtectedRoute";
import ResetPassword from "../../pages/MyAccount/ResetPassword";
import Activate from "../../pages/MyAccount/Activate";
import Store from "../../pages/Store";
import Product from "../../pages/Product";
import Checkout from "../../pages/Checkout";
import Order from "../../pages/MyAccount/Order";
import Downloads from "../../pages/MyAccount/Downloads";
import CustomerSupport from "../../pages/CustomerSupport";
import Docs from "../../pages/Docs";
import TermsConditions from "../../pages/TermsConditions";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import Profile from "../../pages/MyAccount/Profile";
import CookiePolicy from "../../pages/CookiePolicy";

function AppRoutes() {
    return (
        <Routes>
            <Route path={HOME} element={<Home/>}/>
            <Route path={ABOUT} element={<About/>}/>
            <Route path={SIGN_UP} element={<SignUp/>}/>
            <Route path={SIGN_IN} element={<SignIn/>}/>
            <Route path={`${STORE}/*`} element={<Store/>}/>
            <Route path={STORE + CART + CHECKOUT} element={<Checkout/>}/>
            <Route path={`${PRODUCT}/:id`} element={<Product/>}/>
            <Route path={`${MY_ACCOUNT + ACTIVATE_ACCOUNT}/*`} element={<Activate/>}/>
            <Route path={`${MY_ACCOUNT + RESET_PASSWORD}/*`} element={<ResetPassword/>}/>
            <Route path={`${MY_ACCOUNT + ORDER}/:orderId`} element={<ProtectedRoute><Order/></ProtectedRoute>}/>
            <Route path={`${MY_ACCOUNT + DOWNLOADS}`} element={<ProtectedRoute><Downloads/></ProtectedRoute>}/>
            <Route path={`${MY_ACCOUNT + PROFILE}`} element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
            <Route path={`${MY_ACCOUNT}/*`} element={<ProtectedRoute><MyAccount/></ProtectedRoute>}/>
            <Route path={SUPPORT} element={<CustomerSupport/>}/>
            <Route path={DOCS} element={<Docs/>}/>
            <Route path={TERMS} element={<TermsConditions/>}/>
            <Route path={PRIVACY} element={<PrivacyPolicy/>}/>
            <Route path={COOKIE} element={<CookiePolicy/>}/>
            <Route path={NOT_FOUND} element={<NoMatch/>}/>
            <Route path={NO_MATCH} element={<NoMatch/>}/>
        </Routes>
    );
}

export default AppRoutes;