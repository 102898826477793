import {Link} from "@mui/material";
import scalaLogo from "../../assets/scala-full-color.svg";
import playLogo from "../../assets/play_full_color.svg";
import reactLogo from "../../assets/React-icon.svg";
import * as React from "react";
import {
    PLAY_FRAMEWORK_ALT,
    PLAY_FRAMEWORK_WEB,
    POWERED, REACT_ALT,
    REACT_WEB,
    SCALA_ALT,
    SCALA_WEB
} from "../../helpers/globalConst";

function PoweredBy() {
    return (
        <>
            {`${POWERED} `}
            <Link href={SCALA_WEB}>
                <img src={scalaLogo} alt={SCALA_ALT} style={{height: 24, verticalAlign: 'middle'}}/>
            </Link>
            {` `}
            <Link href={PLAY_FRAMEWORK_WEB}>
                <img src={playLogo} alt={PLAY_FRAMEWORK_ALT} style={{height: 24, verticalAlign: 'middle'}}/>
            </Link>
            {` `}
            <Link href={REACT_WEB}>
                <img src={reactLogo} alt={REACT_ALT} style={{height: 24, verticalAlign: 'middle'}}/>
            </Link>
        </>
    );
}

export default PoweredBy;