import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import {Typography} from "@mui/material";

function Action({totalItems = 0, cartItemsTotal = 0, disabled = false, handleBack = () => {}}) {
    return (
        <>
            <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold'}}>
                Order total ({totalItems} item{totalItems > 1 ? 's' : ''}):
                ${cartItemsTotal.toFixed(2)}
            </Typography>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid lg={6} md={8} sm={12} xs={12}>
                    <Button
                        fullWidth
                        variant='contained'
                        sx={{mt: 3, mb: 2}}
                        disabled={disabled}
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid lg={6} md={8} sm={12} xs={12}>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        sx={{mt: 3, mb: 2}}
                        disabled={disabled}
                    >
                        Place order
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default Action;