import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query'
import {baseApi, baseApiReducer} from '../services/baseService'
import {cartReducer} from "./store/cartSlice";
import storage from "redux-persist/lib/storage";
import {FLUSH, PAUSE, PERSIST, persistCombineReducers, persistStore, PURGE, REGISTER, REHYDRATE,} from "redux-persist"
import {identityReducer} from "./store/identitySlice";

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [baseApi.reducerPath]
}

const persistedReducer = persistCombineReducers(persistConfig, {cartReducer, identityReducer});

export const store = configureStore({
    reducer: {
        root: persistedReducer,
        [baseApi.reducerPath]: baseApiReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(baseApi.middleware)
});

setupListeners(store.dispatch)

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
