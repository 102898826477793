import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Card, CardContent, Container} from "@mui/material";
import Typography from "@mui/material/Typography";

function About() {
    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid lg={12} md={12} sm={12} xs={12}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent>
                                <Typography variant="h3" gutterBottom align={'center'} sx={{fontWeight: 'bold'}}>
                                    About Us
                                </Typography>
                                <Typography align={'justify'}>
                                    Welcome to <b>RK Global Group</b>, a cutting-edge technology Florida-based company
                                    founded in 2024. We specialize in innovative software development, Magento
                                    extensions, and Netsuite
                                    scripts development. Our team of experts is dedicated to delivering top-notch
                                    solutions that drive business success.<br/><br/>
                                    At RK Global Group, we believe in harnessing the power of technology to empower
                                    businesses. Our mission is to provide exceptional solutions that streamline
                                    processes, enhance efficiency, and boost profitability. We strive to build
                                    long-lasting relationships with our clients, understanding their unique needs and
                                    challenges.<br/><br/>
                                    Our journey began in 2023, with a vision to revolutionize the software development
                                    landscape. Our founders, seasoned professionals with decades of combined experience,
                                    saw an opportunity to create a company that would bridge the gap between technology
                                    and business needs. Today, we're proud to be a trusted partner for businesses
                                    worldwide.<br/><br/>
                                    Our expertise spans across various domains, including:
                                    <ul>
                                        <li>
                                            Software Development: We design, develop, and deploy custom software
                                            solutions that meet specific business requirements.
                                        </li>
                                        <li>
                                            Magento Extensions: Our experts create tailored extensions to enhance the
                                            functionality of Magento-based e-commerce platforms.
                                        </li>
                                        <li>
                                            Netsuite Scripts: We develop custom scripts to automate and optimize
                                            business processes within the Netsuite ecosystem.
                                        </li>
                                    </ul>
                                    Our team comprises talented professionals with diverse skill sets, ensuring a
                                    collaborative approach to project delivery. We prioritize innovation, quality, and
                                    customer satisfaction, driving us to continuously improve and expand our
                                    services.<br/><br/>
                                    At RK Global Group, we value:
                                    <ul>
                                        <li>
                                            Innovation: Embracing cutting-edge technologies and methodologies to stay
                                            ahead of the curve.
                                        </li>
                                        <li>
                                            Excellence: Delivering high-quality solutions that exceed client
                                            expectations.
                                        </li>
                                        <li>
                                            Collaboration: Fostering a culture of teamwork and open communication.
                                        </li>
                                        <li>
                                            Customer-centricity: Understanding and addressing client needs with
                                            personalized support.
                                        </li>
                                    </ul>
                                    As we continue to grow and evolve, our commitment to delivering exceptional
                                    solutions remains unwavering. Thank you for considering RK Global Group as your
                                    trusted technology partner.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
}

export default About;