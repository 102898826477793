import * as React from "react";
import {Card, CardActions, CardContent, CardMedia, Container, Typography} from "@mui/material";
import netsuiteBanner from "../../assets/netsuite-logo.jpg";
// import {BROWSE_STORE} from "../../helpers/globalConst";
// import {NETSUITE, STORE} from "../../helpers/routes";
// import ButtonLinkRightArrow from "../../particles/ButtonLinkRightArrow";
// import {useNavigate} from "react-router-dom";

function NetSuiteCard() {
    // const navigate = useNavigate();
    // const navigateToStore = () => {
    //     navigate(STORE + NETSUITE);
    // }

    return (
        <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}} maxWidth="xs">
            <Card sx={{height: '100%'}}>
                <CardMedia
                    sx={{height: {lg: '70%', md: '70%', sm: '70%', xs: '60%'}, '&:hover': {cursor: 'pointer'}}}
                    image={netsuiteBanner}
                    //onClick={navigateToStore}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5"
                                sx={{fontSize: {lg: 21, md: 21, sm: 21, xs: 18}, '&:hover': {cursor: 'pointer'}}}
                                //onClick={navigateToStore}
                        >
                        Oracle NetSuite
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{fontSize: 18, '&:hover': {cursor: 'pointer'}}}
                                //onClick={navigateToStore}
                        >
                        Scripts & Plug-ins
                    </Typography>
                </CardContent>
                <CardActions sx={{pl: 1.5}}>
                    COMING SOON...
                    {/*<ButtonLinkRightArrow text={BROWSE_STORE} to={STORE + NETSUITE}/>*/}
                </CardActions>
            </Card>
        </Container>
    );
}

export default NetSuiteCard;