import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import * as React from "react";
import {useContext} from "react";
import Button from "@mui/material/Button";
import {useRequestActivationEmailMutation} from "../../services/identityService";
import NotificationContext from "../NotificationContext";
import Loading from "../Loading";
import {CUSTOMER_ACTIVATION_EMAIL_FAILED, CUSTOMER_ACTIVATION_EMAIL_SENT} from "../../helpers/notifications";

function CustomerNotActiveDialog({show = false, email = '', handleClose = () => {}}) {
    const notificationCtx = useContext(NotificationContext);
    const [requestActivationEmail, {isLoading}] = useRequestActivationEmailMutation();
    const onSubmit = (email: string) => {
        requestActivationEmail({email: email})
            .unwrap()
            .then(
                () => {
                    notificationCtx.showNotification(CUSTOMER_ACTIVATION_EMAIL_SENT, 'success');
                    handleClose();
                }
            )
            .catch(
                () => {
                    notificationCtx.showNotification(CUSTOMER_ACTIVATION_EMAIL_FAILED, 'error');
                }
            );

    }

    return (
        <Dialog open={show} onClose={handleClose}>
            <Box>
                <DialogTitle>Your account is not activated yet.</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you have not received an activation email yet or the activation link is expired,
                        please click on the 'SEND' button below. <br />We
                        will re-send you the activation email.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type={'button'} onClick={() => onSubmit(email)} variant={'contained'}>Send</Button>
                </DialogActions>
            </Box>
            <Loading show={isLoading}/>
        </Dialog>
    );
}

export default CustomerNotActiveDialog;