import * as React from "react";
import Cart from "../../components/Cart";


function CartStep({setStep = (step: number) => {}}) {
    return (
        <Cart setStep={setStep}/>
    );
}

export default CartStep;