import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Card, CardContent, Container} from "@mui/material";
import Typography from "@mui/material/Typography";

function CookiePolicy() {
    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid lg={12} md={12} sm={12} xs={12}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent>
                                <Typography variant="h3" gutterBottom align={'center'} sx={{fontWeight: 'bold'}}>
                                    Cookie Policy
                                </Typography>
                                <Typography variant="h6" gutterBottom align={'center'} sx={{fontWeight: 'bold'}}>
                                    <span>Last Updated On 23-May-2024</span>
                                    <br/><span>Effective Date 01-Jan-2024</span>
                                </Typography>

                                <Typography align={'justify'}>
                                    In this Cookie Policy we will provide you with detailed information on how RK Global Group (hereinafter - the "we" or "our"), shall undertake to ensure the security
                                    of personal information and the protection of rights of the visitors and users of the websites (hereinafter - the "Visitors", "You") while you use our websites
                                    including but not limited to https://www.rkglobalgroup.com (hereinafter - the "Site") and the content on it.
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            What is a cookie?
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            A cookie is a small file placed onto your device that enables our Site features
                                            and functionality. For example, cookies can enable us to identify your device
                                            and secure your access to the Site. Cookies also allow the Site to remember
                                            information about your browsing on the Site for a while and to recognize you
                                            the next time you visit the Site. All this allows us to give you the opportunity
                                            to use the Site comfortably and to make the Site even more user-friendly.
                                        </Typography>
                                        <br />
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Why do we use cookies?
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            We use cookies for the following main purposes:<br/><br/>
                                            - To ensure efficient and safe functioning of the Site. We use cookies to
                                            enable and support our security features, and to help us detect malicious
                                            activity on our Site.
                                        </Typography>
                                        <br />
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            What cookies do we use?
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            Each time you visit our Site, the long-term (persistent) cookies may be
                                            created,
                                            which stay in your browser after you sign-up and will be read by us when
                                            you return to our Site and not deleted after you finish browsing our Site,
                                            and the short-term (session) cookies, which expire or are deleted after you
                                            finish browsing our Site
                                            (i.e. they usually last during the current visit to our Site or browsing
                                            session).<br/><br/>
                                            Cookies used by the Company:<br/><br/>
                                            - Strictly required or necessary cookies. These cookies are required for the
                                            operation of our Site.
                                            They include, for example, cookies that enable storage of information filled
                                            by you during the browsing session,
                                            enable you to log into secure areas of our Site. Without these cookies
                                            operation of the Site would be impossible
                                            or its functioning may be severely affected.
                                        </Typography>
                                        <br />
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1"
                                                    sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            How to refuse or block cookies?
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            Many web browsers are set so that they would automatically accept all cookies.
                                            The Visitors may, at their discretion, manage, block or delete cookies,
                                            if the settings of their browser or device enable them to do so. Nevertheless,
                                            if you refuse or block the cookies or other similar technologies,
                                            some functions of the Site may be inaccessible to you or they may operate not properly.
                                            We draw your attention that necessary cookies are critical for functioning of our Site,
                                            and in case of your objections, some features of the Site may not work or may not work properly.
                                            You may require that we delete all the data about you, as collected and processed
                                            with the help of the cookies, by contacting to the email address support@rkglobalgroup.com.
                                            You may find more information about how to delete cookies,
                                            as well as the other useful information related to the use of the cookies,
                                            on the website http://www.allaboutcookies.org/.
                                        </Typography>
                                        <br/>
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1"
                                                    sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Do we update Cookie Policy?
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            This Cookie Policy may be updated by us from time to time. We will inform you about the updates, by providing the new version of the Cookie Policy.
                                            For this reason, we recommend you periodically visit our Site, where you will always find the latest version of this Cookie Policy.
                                            This Cookie Policy shall be applied from the date of announcement of it on the Site.
                                        </Typography>
                                    </li>
                                </ul>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
}

export default CookiePolicy;