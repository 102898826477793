import * as React from "react";
import Typography from "@mui/material/Typography";
import {CategoryTitleTypography} from "../../particles/CategoryTitleTypography";

function NoMatch() {
    return (
        <>
            <CategoryTitleTypography>Oops!</CategoryTitleTypography>
            <CategoryTitleTypography>404</CategoryTitleTypography>
            <Typography sx={{textAlign: 'center', fontWeight: 'bold'}}>
                Sorry, page not found on this server
            </Typography>
        </>
    );
}

export default NoMatch;