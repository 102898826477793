import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {IconButton} from "@mui/material";

function MenuDrawerIcon({open = false, handleDrawerOpen = () => {}}) {
    return (
        <IconButton
            color="inherit"
            aria-label="open-drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{
                //marginRight: 5,
                ...(open && { display: 'none' }),
            }}
        >
            <MenuIcon/>
        </IconButton>
    );
}

export default MenuDrawerIcon;