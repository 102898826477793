import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Box, Card, CardContent, Container, IconButton, InputAdornment, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import {useForm} from "react-hook-form";
import {useSignInMutation} from "../../services/identityService";
import NotificationContext from "../NotificationContext";
import Notification from "../Notification";
import Loading from "../Loading";
import {Link, useNavigate} from "react-router-dom";
import AvatarLockOutlinedIcon from "../../particles/AvatarLockOutlinedIcon";
import TextField from "@mui/material/TextField";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {HOME} from "../../helpers/routes";
import ForgotPasswordDialog from "../ForgotPasswordDialog";
import {yupResolver} from "@hookform/resolvers/yup";
import {getIdentityData, signInSchema} from "../../helpers/helper";
import {SIGN_IN_FAILED} from "../../helpers/notifications";
import LoggedInIdentityContext from "../LoggedInIdentityContext";
import lodash from "lodash";
import CustomerNotActiveDialog from "../CustomerNotActiveDialog";
import {useLazyGetCartQuery, useSaveCartMutation} from "../../services/cartService";
import {useAppDispatch} from "../../app/hooks";
import {saveIdentity} from "../../app/store/identitySlice";
import {getCartContent, updateCart} from "../../app/store/cartSlice";
import {Action} from "@reduxjs/toolkit";
import {LoggedInIdentity} from "../../helpers/interfaces";
import {store} from "../../app/store";

function SignInCard() {
    const navigate = useNavigate();
    const notificationCtx = useContext(NotificationContext);
    const loggedInIdentityCtx = useContext(LoggedInIdentityContext);
    const {
        register, getValues, handleSubmit,
        formState: {errors}
    } = useForm({resolver: yupResolver(signInSchema)});
    const [signIn, {isLoading}] = useSignInMutation();
    const [showPassword, setShowPassword] = useState(false);
    const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
    const [openCustomerNotActiveDialog, setOpenCustomerNotActiveDialog] = useState(false);
    const [getCart] = useLazyGetCartQuery();
    const [saveCart] = useSaveCartMutation();
    const dispatch = useAppDispatch();

    const asyncDispatch = async (action: Action) => {
        return dispatch(action);
    };

    // eslint-disable-next-line
    const getLoggedInIdentity = async () => {
        loggedInIdentityCtx.getLoggedInIdentity();
    }

    useEffect(() => {
        getLoggedInIdentity().then(
            () => {
                if (!(lodash.isEmpty(loggedInIdentityCtx.loggedInIdentity) || lodash.isNil(loggedInIdentityCtx.loggedInIdentity))) {
                    navigate(HOME);
                }
            }
        )
    }, [getLoggedInIdentity, loggedInIdentityCtx.loggedInIdentity, navigate]);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickOpenForgotPasswordDialog = () => {
        setOpenForgotPasswordDialog(true);
    };

    const handleCloseForgotPasswordDialog = () => {
        setOpenForgotPasswordDialog(false);
    };

    const handleOpenCustomerNotActiveDialog = () => {
        setOpenCustomerNotActiveDialog(true);
    };

    const handleCloseCustomerNotActiveDialog = () => {
        setOpenCustomerNotActiveDialog(false);
    };

    const navigateHome = () => {
        navigate(HOME);
        navigate(0);
    }

    const handleSignIn = (identityData: LoggedInIdentity) => {
        asyncDispatch(saveIdentity(identityData))
            .then(() => {
                return getCart(identityData.id).unwrap();
            })
            .then((cart) => {
                const cartPayload = lodash.cloneDeep(cart);
                if (lodash.isNil(cartPayload.customerId)) cartPayload.customerId = identityData.id
                return asyncDispatch(updateCart(cartPayload));
            })
            .then(() => {
                return saveCart(getCartContent(store.getState())).unwrap()
            })
            .then(() => {
                navigateHome();
            })
            .catch(() => navigateHome());
    }

    const onSubmit = async (data: any) => {
        signIn(data).unwrap().then((res) => {
            const identityData = getIdentityData(res);
            if (!lodash.isUndefined(identityData)) {
                handleSignIn(identityData)
            } else {
                navigateHome();
            }
        })
            .catch((err) => {
                    if (lodash.get(err, 'data.content.error') === 'user_is_not_active') {
                        handleOpenCustomerNotActiveDialog();
                    } else {
                        notificationCtx.showNotification(SIGN_IN_FAILED, 'error')
                    }
                }
            );
    }

    return (
        <>
            <Container sx={{pt: 2, pb: 3}} maxWidth='xs'>
                <Card variant="outlined">
                    <CardContent>
                        <Container component='div' sx={{display: 'flex', justifyContent: 'center'}}>
                            <AvatarLockOutlinedIcon/>
                        </Container>
                        <Typography component='h1' variant='h5' sx={{textAlign: 'center'}}>
                            Sign in
                        </Typography>
                        <Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <TextField
                                        {...register('email', {required: true})}
                                        required
                                        fullWidth
                                        id='email'
                                        label='Email Address'
                                        autoComplete='email'
                                        autoFocus
                                        error={!!errors.email}
                                        variant='filled'
                                        helperText={errors.email?.message}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        {...register('password', {required: true})}
                                        required
                                        fullWidth
                                        label='Password'
                                        type={showPassword ? 'text' : 'password'}
                                        id='password'
                                        autoComplete='current-password'
                                        error={!!errors.password}
                                        variant='filled'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        helperText={errors.password?.message}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    {/*<FormControlLabel
                                        {...register('remember')}
                                        control={<Checkbox value='remember' color='primary'
                                                           defaultChecked/>}
                                        label='Remember me'
                                    />*/}
                                </Grid>
                            </Grid>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{mt: 3, mb: 2}}
                            >
                                Sign In
                            </Button>
                            <Grid container justifyContent='flex-end'>
                                <Grid md={6} xs={12}>
                                    <Link onClick={handleClickOpenForgotPasswordDialog} to={''}>
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid md={6} xs={12} sx={{textAlign: {sx: 'start', md: 'end'}}}>
                                    <Link to='/sign-up'>
                                        Don't have an account? Sign Up
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {notificationCtx.open && <Notification/>}
            <Loading show={isLoading}/>
            <ForgotPasswordDialog show={openForgotPasswordDialog} handleClose={handleCloseForgotPasswordDialog}/>
            <CustomerNotActiveDialog show={openCustomerNotActiveDialog} email={getValues('email')}
                                     handleClose={handleCloseCustomerNotActiveDialog}/>
        </>
    );
}

export default SignInCard;