import * as React from "react";
import {Appearance, loadStripe, StripeElementsOptions} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {STRIPE_KEY} from "../../helpers/globalConst";
import ReviewOrder from "../../components/PlaceOrder";
import {PlaceOrderProps} from "../../helpers/interfaces";

const stripePromise = loadStripe(STRIPE_KEY);

function PlaceOrderStep(placeOrderProps: PlaceOrderProps) {
    const appearance: Appearance = {
        theme: 'flat',
    };
    const options: StripeElementsOptions = {
        mode: 'payment',
        amount: 50,
        currency: 'usd',
        paymentMethodCreation: 'manual',
        appearance
    };

    return (
        <Elements options={options} stripe={stripePromise}>
            <ReviewOrder {...placeOrderProps}/>
        </Elements>
    );
}

export default PlaceOrderStep;