import {styled, Typography} from "@mui/material";


export const SloganTypography = styled(Typography)(({theme}) => ({
    color: '#002e53',
    fontWeight: 'bold',
    [theme.breakpoints.up('xs')]: {
        fontSize: 11.5
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: 20.5
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 26.5
    }
}));