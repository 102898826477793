import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import MagentoCard from "../../components/MagentoCard";
import NetSuiteCard from "../../components/NetSuiteCard";
import ActionList from "../../components/ActionList";


function Home() {
    return (
        <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
            <Grid container lg={8} md={12} sm={12} xs={12} columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid lg={6} md={6} sm={12} xs={12}>
                    <MagentoCard/>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12}>
                    <NetSuiteCard/>
                </Grid>
            </Grid>
            <Grid lg={4} md={12} sm={12} xs={12}>
                <ActionList/>
            </Grid>
        </Grid>
    );
}

export default Home;