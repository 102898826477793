import * as React from "react";
import {Box, Container} from "@mui/material";
import Copyright from "../Copyright";

const boxStyle = {
    py: 3,
    px: 2,
    mt: 'auto',
    backgroundColor: 'transparent'
};

const containerStyle = {
    display: 'flex',
    justifyContent: 'center'
};

function Footer() {
    return (
        <Box component="footer" sx={boxStyle}>
            <Container maxWidth="sm" sx={containerStyle}>
                <Copyright/>
            </Container>
        </Box>
    );
}

export default Footer;