import {Backdrop, CircularProgress} from "@mui/material";
import * as React from "react";

function Loading ({show = false}) {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={show}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Loading;