import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

function Total({orderTotal = 0}) {
    return (
        <>
            <Grid lg={8} md={8} sm={8} xs={8}>
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                <strong>Total Before Tax:</strong><br/>
                Tax Collected:<br/>
                <strong>Grand Total:</strong>
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                <strong>${orderTotal.toFixed(2)}</strong><br/>
                $0.00<br/>
                <strong>${orderTotal.toFixed(2)}</strong>
            </Grid>
        </>
    )
}

export default Total;