import React, {Fragment, useContext} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Divider, ListItem} from "@mui/material";
import {Download} from "../../../helpers/interfaces";
import {useLazyGetDownloadQuery} from "../../../services/orderService";
import Button from "@mui/material/Button";
import NotificationContext from "../../../components/NotificationContext";
import Notification from "../../../components/Notification";
import Loading from "../../../components/Loading";
import {FILE_NOT_FOUND} from "../../../helpers/notifications";

interface ItemProps {
    idx: number,
    download: Download
}

function Item(itemProps: ItemProps) {
    const notificationCtx = useContext(NotificationContext);
    const {idx, download} = itemProps;
    const [getDownload, result] = useLazyGetDownloadQuery();

    const handleDownload = () => {
        getDownload(download.location).unwrap()
            .catch(err => notificationCtx.showNotification(FILE_NOT_FOUND, 'error'))
    }

    return (
        <Fragment key={idx}><ListItem secondaryAction={
            <Button onClick={handleDownload}>
                DOWNLOAD
            </Button>
        }>
            <Grid lg={6} md={6} sm={6} xs={6}>
                {download.itemTitle}
            </Grid>
        </ListItem>
            <Divider/>
            {notificationCtx.open && <Notification/>}
            <Loading show={result.isLoading}/>
        </Fragment>
    )
}

export default Item;