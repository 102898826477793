import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Box, Card, CardContent, Container, IconButton, InputAdornment, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {useForm} from "react-hook-form";
import {useSignUpMutation} from "../../services/identityService";
import NotificationContext from "../NotificationContext";
import Notification from "../Notification";
import {errorNotification, signUpSchema} from "../../helpers/helper";
import Loading from "../Loading";
import SignUpSuccessDialog from "./SignUpSuccessDialog";
import ActivationEmailFailedDialog from "../ActivationEmailFailedDialog";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {HOME, PRIVACY, SIGN_IN, TERMS} from "../../helpers/routes";
import AvatarLockOutlinedIcon from "../../particles/AvatarLockOutlinedIcon";
import TextField from "@mui/material/TextField";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {yupResolver} from "@hookform/resolvers/yup";
import LoggedInIdentityContext from "../LoggedInIdentityContext";
import lodash from "lodash";


function SignUpCard() {
    const location = useLocation();
    const navigate = useNavigate();
    const notificationCtx = useContext(NotificationContext);
    const loggedInIdentityCtx = useContext(LoggedInIdentityContext);
    const {register, getValues,
        handleSubmit, formState: {errors}} = useForm({resolver: yupResolver(signUpSchema)});
    const [signUp, {isLoading}] = useSignUpMutation();
    const [showPassword, setShowPassword] = useState(false);
    const [openSignUpSuccess, setOpenSignUpSuccess] = useState(false);
    const [openActivationEmailFailed, setOpenActivationEmailFailed] = useState(false);

    // eslint-disable-next-line
    const getLoggedInIdentity = async () => {
        loggedInIdentityCtx.getLoggedInIdentity();
    }

    useEffect(() => {
        getLoggedInIdentity().then(
            () => {
                if (location.pathname !== HOME
                    && !lodash.isNil(loggedInIdentityCtx.loggedInIdentity)
                    && !lodash.isEmpty(loggedInIdentityCtx.loggedInIdentity)) {
                    navigate(HOME);
                    navigate(0);
                }
            }
        )
    }, [getLoggedInIdentity, loggedInIdentityCtx.loggedInIdentity, navigate, location.pathname])

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onSubmit = (data: any) => {
        signUp(data)
            .unwrap()
            .then(() => setOpenSignUpSuccess(true))
            .catch((error) => {
                notificationCtx.showNotification(errorNotification(error), 'error');
                setOpenActivationEmailFailed(true);
            })
    }

    const closeSignUpSuccess = () => {
        setOpenSignUpSuccess(false);
    }

    const closeActivationEmailFailed = () => {
        setOpenActivationEmailFailed(false);
    };

    return (
        <>
            <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}
                       maxWidth="xs">
                <Card variant='outlined' sx={{height: '100%'}}>
                    <CardContent>
                        <Container component='div' sx={{display: 'flex', justifyContent: 'center', height: '100%'}}>
                            <AvatarLockOutlinedIcon/>
                        </Container>
                        <Typography component='h1' variant='h5' sx={{textAlign: 'center'}}>
                            Sign up
                        </Typography>
                        <Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid xs={12} sm={6}>
                                    <TextField
                                        {...register('firstName', {required: true})}
                                        autoComplete='given-name'
                                        required
                                        fullWidth
                                        id='firstName'
                                        label='First Name'
                                        autoFocus
                                        error={!!errors.firstName}
                                        variant='filled'
                                        helperText={errors.firstName?.message}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6}>
                                    <TextField
                                        {...register('lastName', {required: true})}
                                        required
                                        fullWidth
                                        id='lastName'
                                        label='Last Name'
                                        autoComplete='family-name'
                                        error={!!errors.lastName}
                                        variant='filled'
                                        helperText={errors.lastName?.message}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        {...register('email', {required: true})}
                                        required
                                        fullWidth
                                        id='email'
                                        label='Email Address'
                                        autoComplete='email'
                                        error={!!errors.email}
                                        variant='filled'
                                        helperText={errors.email?.message}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <TextField
                                        {...register('password', {required: true})}
                                        required
                                        fullWidth
                                        label='Password'
                                        type={showPassword ? 'text' : 'password'}
                                        id='password'
                                        autoComplete='new-password'
                                        error={!!errors.password}
                                        variant='filled'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        helperText={errors.password?.message}
                                    />
                                </Grid>
                                {/*<Grid xs={12}>
                                    <FormControlLabel
                                        {...register('allowExtraEmails')}
                                        control={<Checkbox value='allowExtraEmails' color='primary'
                                                           defaultChecked/>}
                                        label='I want to receive inspiration, marketing promotions and updates via email.'
                                    />
                                </Grid>*/}
                            </Grid>
                            <Button type='submit' fullWidth variant='contained' sx={{mt: 5, mb: 2}}>
                                Sign Up
                            </Button>
                            <Grid container justifyContent='flex-end' sx={{mt: 1}}>
                                <Grid>
                                    <Link component={RouterLink} to={SIGN_IN}>
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent='flex-start' sx={{mt: 2}}>
                                <Grid>
                                    <Typography component={'p'} sx={{fontSize: 10}}>
                                        By creating an account, you agree to our <Link component={RouterLink} to={PRIVACY}>Privacy Policy</Link> and <Link component={RouterLink} to={TERMS}>Terms & Conditions</Link>.
                                        <br />Account creation enrolls you to receive communications through email and SMS. You can change the settings through your Profile Settings.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
            {notificationCtx.open && <Notification/>}
            <Loading show={isLoading}/>
            <SignUpSuccessDialog show={openSignUpSuccess} email={getValues('email')} handleClose={closeSignUpSuccess}/>
            <ActivationEmailFailedDialog show={openActivationEmailFailed} email={getValues('email')}
                                         handleClose={closeActivationEmailFailed}/>
        </>
    );
}

export default SignUpCard;