import * as React from "react";
import Toolbar from '@mui/material/Toolbar';
import Logo from "../../components/Logo";
import Cart from "../../components/CartButton";
import AccountMenu from "../../components/AccountMenu";
import {useLocation} from 'react-router-dom';
import {ACTIVATE_ACCOUNT, HOME, MY_ACCOUNT, RESET_PASSWORD} from "../../helpers/routes";
import MenuDrawerIcon from "../../particles/MenuDrawerIcon";
import MyAccountDrawer from "../../components/MyAccountDrawer";
import AppBarStyled from "../../particles/AppBarStyled";
import {MY_ACCOUNT_DRAWER_WIDTH} from "../../helpers/globalConst";
import {useState} from "react";


function TopNav() {
    const location = useLocation();
    const isHomeLocation = location.pathname === HOME;
    const isMyAccountLocation = location.pathname.includes(MY_ACCOUNT);
    const isActivateAccountLocation = location.pathname.includes(MY_ACCOUNT + ACTIVATE_ACCOUNT);
    const isResetPasswordLocation = location.pathname.includes(MY_ACCOUNT + RESET_PASSWORD);
    const showSlogan = isHomeLocation;
    const showMyAccountDrawer = isMyAccountLocation && !isActivateAccountLocation && !isResetPasswordLocation;
    const customStyle = isHomeLocation ? {
        backgroundColor: "transparent",
        boxShadow: "none",
        transition: "none"
    } : isMyAccountLocation ? {} : {transition: "none"};
    const logoWidth = isHomeLocation ? '100%' : '60%';
    const logoMb = isHomeLocation ? 0 : 2;
    const [open, setOpen] = useState(true);
    const drawerWidth = showMyAccountDrawer ? MY_ACCOUNT_DRAWER_WIDTH : 0;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
            <AppBarStyled position="static" style={customStyle} open={open} drawerWidth={drawerWidth}>
                <Toolbar>
                    {showMyAccountDrawer && <MenuDrawerIcon open={open} handleDrawerOpen={handleDrawerOpen}/>}
                    <Logo showSlogan={showSlogan} width={logoWidth} mb={logoMb}/>
                    <Cart/>
                    <AccountMenu/>
                </Toolbar>
            </AppBarStyled>
            {showMyAccountDrawer && <MyAccountDrawer open={open} drawerWidth={drawerWidth} handleDrawerClose={handleDrawerClose}/>}
        </>
    );
}

export default TopNav;