import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../store';
import {Cart, CartItem} from "../../helpers/interfaces";
import lodash from "lodash";

interface CartState {
    cartContent: Cart
}

const initialState: CartState = {
    cartContent: {
        cartItems: []
    }
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<CartItem>) => {
            if (lodash.isUndefined(state.cartContent.cartItems)) state.cartContent['cartItems'] = [];
            const itemInCart = lodash.get(state.cartContent, 'cartItems', [])
                .find((cartItem) => cartItem.productId === action.payload.productId);
            if (!lodash.isUndefined(itemInCart) && !lodash.isUndefined(itemInCart.qty)) {
                itemInCart.qty++;
            } else {
                state.cartContent.cartItems?.push(action.payload);
            }
        },
        removeFromCart: (state, action: PayloadAction<CartItem>) => {
            if (lodash.isUndefined(state.cartContent.cartItems)) state.cartContent['cartItems'] = [];
            const itemInCart = lodash.get(state.cartContent, 'cartItems', [])
                .find((cartItem) => cartItem.productId === action.payload.productId);
            if (!lodash.isUndefined(itemInCart)) lodash.remove(state.cartContent.cartItems, {productId: itemInCart.productId})
        },
        updateQty: (state, action: PayloadAction<CartItem>) => {
            if (lodash.isUndefined(state.cartContent.cartItems)) state.cartContent['cartItems'] = [];
            const itemInCart = lodash.get(state.cartContent, 'cartItems', [])
                .find((cartItem) => cartItem.productId === action.payload.productId);
            if (!lodash.isUndefined(itemInCart)) itemInCart.qty = action.payload.qty
        },
        updateCart: (state, action: PayloadAction<Cart>) => {
            const initialCartItems = lodash.cloneDeep(state.cartContent.cartItems) || [];
            let cartPayload = lodash.cloneDeep(action.payload);
            if (lodash.isUndefined(cartPayload.cartItems) || cartPayload.cartItems.length === 0) {
                if (!lodash.isNil(cartPayload.customerId)) state.cartContent.customerId = cartPayload.customerId;
                return;
            }

            let currentCartItems = lodash.cloneDeep(cartPayload.cartItems);
            cartPayload.cartItems.forEach((cartItem, idx) => {
                const itemInCart = initialCartItems
                    .find((initCartItem) => initCartItem.productId === cartItem.productId);
                if (!lodash.isUndefined(itemInCart) && !lodash.isUndefined(itemInCart.qty)) {
                    const qty = currentCartItems[idx].qty;
                    if (lodash.isUndefined(qty)) {
                        currentCartItems[idx].qty = itemInCart.qty;
                    } else {
                        currentCartItems[idx].qty = qty + itemInCart.qty;
                    }
                }
            });

            initialCartItems.forEach((cartItem, idx) => {
                if (!lodash.isUndefined(cartPayload.cartItems)) {
                    const itemInCart = cartPayload.cartItems
                        .find((currentCartItem) => currentCartItem.productId === cartItem.productId);
                    if (lodash.isUndefined(itemInCart)) currentCartItems.push(cartItem);
                }
            });

            cartPayload.cartItems = currentCartItems;
            state.cartContent = cartPayload;
        },
        clearCart: (state) => {
            state.cartContent = {cartItems: []};
        }
    }
});

export const {
    addToCart,
    removeFromCart,
    updateQty,
    updateCart,
    clearCart
} = cartSlice.actions;
export const getCartContent = (state: RootState) => state.root.cartReducer.cartContent;

export const cartReducer = cartSlice.reducer;