import * as React from "react";
import CartStep from "./cartStep";
import PaymentDetailsStep from "./paymentDetailsStep";
import PlaceOrderStep from "./placeOrderStep";
import Stepper from "../../components/Cart/stepper";
import OrderPlaced from "../OrderPlaced";
import {useState} from "react";
import {PaymentMethod} from "@stripe/stripe-js";

function Checkout() {
    const [activeStep, setActiveStep] = useState(0);
    const [clientSecret, setClientSecret] = useState('');
    const [paymentMethodData, setPaymentMethodData] = useState<PaymentMethod>();
    const [orderId, setOrderId] = useState(0);

    const setStep = (step: number) => {
        setActiveStep(step);
    }

    const setSecret = (secret: string) => {
        setClientSecret(secret);
    }

    const setPaymentMethod = (paymentMethod: PaymentMethod) => {
        setPaymentMethodData(paymentMethod);
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <CartStep setStep={setStep}/>;
            case 1:
                return <PaymentDetailsStep setStep={setStep} setSecret={setSecret} setPaymentMethod={setPaymentMethod}/>;
            case 2:
                return <PlaceOrderStep setStep={setStep} clientSecret={clientSecret} paymentMethod={paymentMethodData} setOrderId={setOrderId}/>;
            case 3:
                return <OrderPlaced orderId={orderId}/>;
            default:
                return <CartStep setStep={setStep}/>;
        }
    }

    return (
        <>
            {activeStep < 3 && <Stepper activeStep={activeStep}/>}
            {getStepContent(activeStep)}
        </>
    );
}

export default Checkout;