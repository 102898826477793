import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Divider, IconButton, ListItemIcon, Tooltip} from "@mui/material";
import {AccountCircleOutlined, Login, Logout, PersonAdd} from '@mui/icons-material';
import AccountAvatar from '../../particles/AccountAvatar';
import LoggedInIdentityContext from "../LoggedInIdentityContext";
import lodash from "lodash";
import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {HOME, MY_ACCOUNT, SIGN_IN, SIGN_UP} from "../../helpers/routes";
import {ACC_MY_ACCOUNT, ACC_SIGN_IN, ACC_SIGN_OUT, ACC_SIGN_UP} from "../../helpers/globalConst";
import {deleteTokens} from "../../helpers/helper";
import {useAppDispatch} from "../../app/hooks";
import {removeIdentity} from "../../app/store/identitySlice";
import {clearCart} from "../../app/store/cartSlice";

export default function AccountMenu() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loggedInIdentityCtx = useContext(LoggedInIdentityContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        dispatch(clearCart());
        dispatch(removeIdentity());
        await deleteTokens();
        navigate(HOME);
        navigate(0);
    }

    useEffect(() => {
        loggedInIdentityCtx.getLoggedInIdentity();
        // eslint-disable-next-line
    }, [handleLogout])

    const hasLoggedInIdentity = !(lodash.isEmpty(loggedInIdentityCtx.loggedInIdentity) || lodash.isNil(loggedInIdentityCtx.loggedInIdentity));

    return (
        <div>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ml: 2}}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <AccountAvatar>{loggedInIdentityCtx.getLoggedInIdentityInitials()}</AccountAvatar>
                </IconButton>
            </Tooltip>
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {!hasLoggedInIdentity && (location.pathname !== SIGN_IN) &&
                    <MenuItem component={RouterLink} to={SIGN_IN} onClick={handleClose}>
                        <ListItemIcon>
                            <Login fontSize="small"/>
                        </ListItemIcon>
                        {ACC_SIGN_IN}
                    </MenuItem>}
                {!hasLoggedInIdentity && ![HOME, SIGN_UP, SIGN_IN].includes(location.pathname) && <Divider/>}
                {!hasLoggedInIdentity && ![SIGN_UP].includes(location.pathname) &&
                    <MenuItem component={RouterLink} to={SIGN_UP} onClick={handleClose}>
                        <ListItemIcon>
                            <PersonAdd fontSize="small"/>
                        </ListItemIcon>
                        {ACC_SIGN_UP}
                    </MenuItem>}
                {hasLoggedInIdentity && <MenuItem component={RouterLink} to={MY_ACCOUNT} onClick={handleClose}>
                    <ListItemIcon>
                        <AccountCircleOutlined fontSize="small"/>
                    </ListItemIcon>
                    {ACC_MY_ACCOUNT}
                </MenuItem>}
                {hasLoggedInIdentity && <MenuItem component={RouterLink} to={HOME} onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    {ACC_SIGN_OUT}
                </MenuItem>}
            </Menu>
        </div>
    );
}