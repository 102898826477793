import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Card, CardContent, Container} from "@mui/material";
import Typography from "@mui/material/Typography";

function PrivacyPolicy() {
    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid lg={12} md={12} sm={12} xs={12}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent>
                                <Typography variant="h3" gutterBottom align={'center'} sx={{fontWeight: 'bold'}}>
                                    Privacy Policy
                                </Typography>
                                <Typography variant="h6" gutterBottom align={'center'} sx={{fontWeight: 'bold'}}>
                                    <span>Last Updated On 23-May-2024</span>
                                    <br/><span>Effective Date 01-Jan-2024</span>
                                </Typography>

                                <Typography align={'justify'}>
                                    This Privacy Policy describes the policies of

                                    RK Global Group,
                                    Florida,
                                    United States of America (the),
                                    email: support@rkglobalgroup.com

                                    on the collection,
                                    use and disclosure of your information that we collect
                                    when you use our website ( https://www.rkglobalgroup.com ).
                                    (the "Service"). By accessing or using
                                    the
                                    Service, you are consenting to the collection, use and
                                    disclosure of your information in accordance with this
                                    Privacy Policy. If you do not consent to the same,
                                    please do not access or use the Service.
                                </Typography>
                                <br />
                                <Typography sx={{textAlign: 'justify'}}>
                                    We may modify this Privacy Policy at any time without
                                    any prior notice to you and will post the revised
                                    Privacy Policy on the Service. The revised Policy will
                                    be effective 180 days from when the
                                    revised Policy is posted in the Service and your
                                    continued access or use of the Service after such time
                                    will constitute your acceptance of the revised Privacy
                                    Policy. We therefore recommend that you periodically
                                    review this page.
                                </Typography>

                                <ul>
                                    <li>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Information We Collect:
                                        </Typography>
                                        <p>
                                            We will collect and process the following
                                            personal information about you:
                                        </p>
                                        <ul>
                                            <li>Name</li>
                                            <li>Email</li>
                                            <li>Mobile</li>
                                            <li>Address</li>
                                            <li>Work Address</li>
                                            <li>Payment Info</li>
                                        </ul>
                                        <br />
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            How We Use Your Information:
                                        </Typography>
                                        <p>
                                            We will use the information that we collect
                                            about you for the following purposes:
                                        </p>
                                        <ul>
                                            <li>Marketing/ Promotional</li>
                                            <li>Creating user account</li>
                                            <li>Processing payment</li>
                                            <li>Support</li>
                                            <li>Administration info</li>
                                            <li>Manage customer order</li>
                                            <li>Dispute resolution</li>
                                            <li>Manage user account</li>
                                        </ul>
                                        <br />
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            If we want to use your information for any other
                                            purpose, we will ask you for consent and will
                                            use your information only on receiving your
                                            consent and then, only for the purpose(s) for
                                            which grant consent unless we are required to do
                                            otherwise by law.
                                        </Typography>
                                        <br />
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1" sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Retention Of Your Information:
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            We will retain your personal information with us
                                            for
                                            90 days to 2 years after user accounts remain idle
                                            or for as
                                            long as we need it to fulfill the purposes for
                                            which it was collected as detailed in this
                                            Privacy Policy. We may need to retain certain
                                            information for longer periods such as
                                            record-keeping / reporting in accordance with
                                            applicable law or for other legitimate reasons
                                            like enforcement of legal rights, fraud
                                            prevention, etc. Residual anonymous information
                                            and aggregate information, neither of which
                                            identifies you (directly or indirectly), may be
                                            stored indefinitely.
                                        </Typography>
                                        <br />
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1"
                                                    sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Your Rights:
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            Depending on the law that applies, you may have
                                            a right to access and rectify or erase your
                                            personal data or receive a copy of your personal
                                            data, restrict or object to the active
                                            processing of your data, ask us to share (port)
                                            your personal information to another entity,
                                            withdraw any consent you provided to us to
                                            process your data, a right to lodge a complaint
                                            with a statutory authority and such other rights
                                            as may be relevant under applicable laws. To
                                            exercise these rights, you can write to us at
                                            support@rkglobalgroup.com.
                                            We will respond to your
                                            request in accordance with applicable law.
                                        </Typography>
                                        <br/>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            You may opt-out of direct marketing
                                            communications or the profiling we carry out for
                                            marketing purposes by writing to us at
                                            support@rkglobalgroup.com.
                                        </Typography>
                                        <br/>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            Do note that if you do not allow us to collect
                                            or process the required personal information or
                                            withdraw the consent to process the same for the
                                            required purposes, you may not be able to access
                                            or use the services for which your information
                                            was sought.
                                        </Typography>
                                        <br/>
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1"
                                                    sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Cookies Etc.
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            To learn more about how we use these
                                            and your choices in relation to these tracking
                                            technologies, please refer to our <a href="/cookie-policy">Cookie Policy.</a>
                                        </Typography>
                                        <br/>
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1"
                                                    sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Security:
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            The security of your information is important to
                                            us and we will use reasonable security measures
                                            to prevent the loss, misuse or unauthorized
                                            alteration of your information under our
                                            control. However, given the inherent risks, we
                                            cannot guarantee absolute security and
                                            consequently, we cannot ensure or warrant the
                                            security of any information you transmit to us
                                            and you do so at your own risk.
                                        </Typography>
                                        <br/>
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1"
                                                    sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Third Party Links & Use Of Your Information:
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            Our Service may contain links to other websites
                                            that are not operated by us. This Privacy Policy
                                            does not address the privacy policy and other
                                            practices of any third parties, including any
                                            third party operating any website or service
                                            that may be accessible via a link on the
                                            Service. We strongly advise you to review the
                                            privacy policy of every site you visit. We have
                                            no control over and assume no responsibility for
                                            the content, privacy policies or practices of
                                            any third party sites or services.
                                        </Typography>
                                        <br/>
                                    </li>
                                    <li>
                                        <Typography variant="subtitle1"
                                                    sx={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                                            Grievance / Data Protection Officer:
                                        </Typography>
                                        <Typography variant={'body1'} sx={{textAlign: 'justify'}}>
                                            If you have any queries or concerns about the
                                            processing of your information that is available
                                            with us, you may email our Grievance Officer at
                                            RK Global Group,
                                            email: support@rkglobalgroup.com.
                                            We will address your concerns in accordance with applicable law.
                                        </Typography>
                                    </li>
                                </ul>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
}

export default PrivacyPolicy;