import React from "react";
import Grid from "@mui/material/Unstable_Grid2";

function Header({activeStep = 0}) {
    return (
        <>
            <Grid lg={3} md={6} sm={6} xs={6}>
                ORDER #
            </Grid>
            <Grid lg={5} md={2} sm={2} xs={2}>
                ORDER PLACED
            </Grid>
            <Grid lg={4} md={2} sm={2} xs={2}>
                TOTAL
            </Grid>
        </>
    )
}

export default Header;