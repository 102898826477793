import * as React from "react";
import {Appearance, loadStripe, PaymentMethod, StripeElementsOptions} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import PaymentDetails from "../../components/PaymentDetails";
import {STRIPE_KEY} from "../../helpers/globalConst";

const stripePromise = loadStripe(STRIPE_KEY);

function PaymentDetailsStep({setStep = (step: number) => {}, setSecret = (secret: string) => {}, setPaymentMethod = (paymentMethod: PaymentMethod) => {}}) {
    const appearance: Appearance = {
        theme: 'flat',
    };
    const options: StripeElementsOptions = {
        mode: 'payment',
        amount: 50,
        currency: 'usd',
        paymentMethodCreation: 'manual',
        appearance
    };

    return (
        <Elements options={options} stripe={stripePromise}>
            <PaymentDetails setStep={setStep} setSecret={setSecret} setPaymentMethod={setPaymentMethod}/>
        </Elements>
    );
}

export default PaymentDetailsStep;