import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import CheckoutStepper from "../../particles/CheckoutStepper";

function Stepper({activeStep = 0}) {
    return (
        <Grid container sx={{mt: -5, mb: 2}}>
            <Grid lg md></Grid>
            <Grid lg={5} md={5} sm={12} xs={12} sx={{background: "white", p: 2, borderRadius: 10}}>
                <CheckoutStepper activeStep={activeStep}/>
            </Grid>
            <Grid lg md></Grid>
        </Grid>
    )
}

export default Stepper;