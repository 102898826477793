import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#33587e'
        },
        secondary: {
            main: '#f8642a',
        },
    },
});