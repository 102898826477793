import * as React from "react";
import {useContext} from "react";
import Typography from "@mui/material/Typography";
import {useGetOrderQuery} from "../../../services/orderService";
import Loading from "../../../components/Loading";
import {useParams} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import {Card, CardContent, Container, Divider, List, ListItem} from "@mui/material";

import Item from "./item"
import Total from "./total";
import Notification from "../../../components/Notification";
import NotificationContext from "../../../components/NotificationContext";
import Header from "./header";
import PaymentMethodHeader from "./paymentMethodHeader";
import {useGetPaymentDetailsQuery} from "../../../services/paymentService";
import PaymentMethodItem from "./paymentMethodItem";
import {getOrderDate} from "../../../helpers/helper";

function Order() {
    const notificationCtx = useContext(NotificationContext);
    const {orderId} = useParams();
    const {data: maybeOrder, isLoading: orderLoading} = useGetOrderQuery(orderId || '');
    const {data: maybePaymentDetails, isLoading: paymentDetailsLoading} = useGetPaymentDetailsQuery(orderId || '');
    const orderItems = maybeOrder?.orderItems || [];
    const loading = orderLoading || paymentDetailsLoading;
    const paymentMethod = maybePaymentDetails?.paymentMethod || {};

    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid lg={12} md={12} sm={12} xs={12}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom sx={{fontWeight: 'bold'}}>
                                    Order details
                                </Typography>
                                <Typography variant="h6" gutterBottom >
                                    Order #{maybeOrder?.id}
                                </Typography>
                                <Typography>
                                    Order placed: {getOrderDate(maybeOrder?.createdAt || '')}
                                </Typography>
                                <List sx={{mt: 5}}>
                                    <ListItem secondaryAction={<></>}>
                                        <Header/>
                                    </ListItem>
                                    <Divider/>
                                    {orderItems.map((orderItem, idx) => (
                                        <Item key={idx} idx={idx} orderItem={orderItem}/>
                                    ))}
                                    <ListItem secondaryAction={<></>}>
                                        <Total orderTotal={maybeOrder?.total}/>
                                    </ListItem>
                                </List>
                                <List sx={{mt: 5}}>
                                    <ListItem secondaryAction={<></>}>
                                        <PaymentMethodHeader/>
                                    </ListItem>
                                    <Divider/>
                                    <PaymentMethodItem paymentMethod={paymentMethod} order={maybeOrder}/>
                                </List>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            {notificationCtx.open && <Notification/>}
            <Loading show={loading}/>
        </>
    );
}

export default Order;