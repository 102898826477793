import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Container} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";

function Docs() {
    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid lg={12} md={12} sm={12} xs={12}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent>
                                <Typography variant="h3" gutterBottom align={'center'} sx={{fontWeight: 'bold'}}>
                                    Docs & User Guides
                                </Typography>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <b>How to install Magento 2 extension from a ZIP archive</b>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography align={'justify'}>
                                            Here are the step-by-step instructions for installing a Magento 2 extension
                                            from a ZIP archive:
                                        </Typography><br/>
                                        <Typography sx={{fontWeight: 'bold'}} align={'justify'}>
                                            Prerequisites:
                                        </Typography>
                                        <ul>
                                            <li>
                                                Magento 2 installed and running
                                            </li>
                                            <li>
                                                SSH access to Magento 2 server
                                            </li>
                                            <li>
                                                ZIP archive of the extension (e.g., 'VendorName_ModuleName.zip')
                                            </li>
                                        </ul>
                                        <Typography sx={{fontWeight: 'bold'}} align={'justify'}>
                                            Installation Steps:
                                        </Typography>
                                        <ol>
                                            <li>
                                                <b>Upload the ZIP archive:</b> Use SSH to upload the ZIP archive to your
                                                Magento 2 server. You can use a command like:<br/>
                                                <pre><code>$ scp VendorName_ModuleName.zip username@server-ip:~/</code></pre>
                                                Replace 'username' with your SSH username and 'server-ip' with your
                                                server's IP address.<br/><br/>
                                            </li>
                                            <li>
                                                <b>Connect to Magento 2 server:</b> Connect to your server via SSH. Use
                                                the command:<br/>
                                                <pre><code>$ ssh username@server-ip</code></pre>
                                                Replace 'username' with your SSH username and 'server-ip' with your
                                                server's IP address.<br/><br/>
                                            </li>
                                            <li>
                                                <b>Extract the archive:</b> Navigate to the '~/' directory ('~' is an
                                                alias to user home directory: /home/username). Use a command:<br/>
                                                <pre><code>$ cd ~</code></pre>
                                                Extract the ZIP archive using the command:<br/>
                                                <pre><code>$ unzip VendorName_ModuleName.zip</code></pre>
                                                This will create a new folder with the vendor name (e.g., 'VendorName').<br/><br/>
                                            </li>
                                            <li>
                                                <b>Copy the extension folder:</b> If this is your very first attempt to
                                                install a Magento 2 extension manually, create 'app/code/' directory
                                                using:<br/>
                                                <pre><code>$ mkdir /var/www/html/app/code</code></pre>
                                                Copy the extracted folder to the
                                                'app/code/' directory using the command:<br/>
                                                <pre><code>$ cp VendorName /var/www/html/app/code/</code></pre>
                                                assuming your Magento 2 installation is in '/var/www/html/'.<br/><br/>
                                            </li>
                                            <li>
                                                <b>Enable the extension:</b> Navigate to your Magento 2 installation
                                                directory, e.g.
                                                '/var/www/html/'. Use a command:<br/>
                                                <pre><code>$ cd /var/www/html/</code></pre>
                                                Enable Magento 2 maintenance mode using the command:<br/>
                                                <pre><code>$ bin/magento maintenance:enable</code></pre>
                                                Run the following command to enable the extension:<br/>
                                                <pre><code>$ bin/magento module:enable VendorName_ModuleName</code></pre>
                                                Replace 'VendorName' and 'ModuleName' with the actual names from the
                                                extension, e.g. RkGlobal_AdminReindex<br/><br/>
                                            </li>
                                            <li>
                                                <b>Register the extension:</b> Run the following command to register the
                                                extension:<br/>
                                                <pre><code>$ bin/magento setup:upgrade</code></pre>
                                                <pre><code>$ bin/magento setup:di:compile</code></pre>
                                                <pre><code>$ bin/magento setup:static-content:deploy -f</code></pre>
                                            </li>
                                            <li>
                                                <b>Clean cache:</b> Clean the cache using the command:<br/>
                                                <pre><code>$ bin/magento cache:clean</code></pre>
                                                <pre><code>$ bin/magento cache:flush</code></pre>
                                            </li>
                                            <li>
                                                <b>Reindex Indexers:</b> Reindex Magento 2 Indexers using the
                                                command:<br/>
                                                <pre><code>$ bin/magento indexer:reindex</code></pre>
                                            </li>
                                            <li>
                                                <b>Disable Maintenance Mode:</b> Disable Maintenance Mode using the
                                                command:<br/>
                                                <pre><code>$ bin/magento maintenance:disable</code></pre>
                                            </li>
                                        </ol>
                                        <Typography align={'justify'}>
                                            That's it! Your Magento 2 extension should now be installed and ready to
                                            use.<br/><br/>
                                            <i>Remember to adjust the commands according to your server's configuration and
                                                file structure.</i>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
        </>
    );
}

export default Docs;