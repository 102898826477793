import * as React from "react";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Button from "@mui/material/Button";

function ButtonAddToCart({text = '', onClick = () => {}}) {
    return (
        <Button size="small" variant="contained" onClick={onClick} endIcon={<AddShoppingCartIcon/>}>{text}</Button>
    );
}

export default ButtonAddToCart;