import * as React from "react";
import {useContext} from "react";
import Typography from "@mui/material/Typography";
import {OrderPlacedProps} from "../../helpers/interfaces";
import {useGetOrderQuery} from "../../services/orderService";
import NotificationContext from "../../components/NotificationContext";
import {useGetPaymentDetailsQuery} from "../../services/paymentService";
import Grid from "@mui/material/Unstable_Grid2";
import {Card, CardContent, Container, Divider, Link, List, ListItem} from "@mui/material";
import {getOrderDate} from "../../helpers/helper";
import Notification from "../../components/Notification";
import Loading from "../../components/Loading";
import Header from "./header";
import Item from "./item";
import Total from "./total";
import PaymentMethodHeader from "./paymentMethodHeader";
import PaymentMethodItem from "./paymentMethodItem";
import {useAppSelector} from "../../app/hooks";
import {Link as RouterLink} from "react-router-dom";
import {DOWNLOADS, MY_ACCOUNT} from "../../helpers/routes";

function OrderPlaced(orderPlacedProps: OrderPlacedProps) {
    const notificationCtx = useContext(NotificationContext);
    const {data: maybeOrder, isLoading: orderLoading} = useGetOrderQuery(orderPlacedProps.orderId.toString());
    const {
        data: maybePaymentDetails,
        isLoading: paymentDetailsLoading
    } = useGetPaymentDetailsQuery(orderPlacedProps.orderId.toString());
    const orderItems = maybeOrder?.orderItems || [];
    const loading = orderLoading || paymentDetailsLoading;
    const paymentMethod = maybePaymentDetails?.paymentMethod || {};
    const loggedInIdentityState = useAppSelector((state) => state.root.identityReducer.loggedInIdentity);

    return (
        <>
            <Grid container columnSpacing={{xs: 1, sm: 1, md: 1, lg: 1}}>
                <Grid lg={12} md={12} sm={12} xs={12}>
                    <Container sx={{pt: 2, pb: 3, height: '100%', minHeight: {lg: 550, md: 550, sm: 550, xs: 400}}}>
                        <Card variant="outlined" sx={{height: '100%'}}>
                            <CardContent>
                                <Typography variant="h4" gutterBottom align={'center'} sx={{fontWeight: 'bold'}}>
                                    Thank you for your order!
                                </Typography>
                                <Typography gutterBottom align={'center'}>
                                    An email confirmation has been sent
                                    to <strong>{loggedInIdentityState.email}</strong>
                                </Typography><br/>
                                <Typography gutterBottom>
                                    You can find your downloads here: <Link component={RouterLink} to={MY_ACCOUNT + DOWNLOADS}>My Downloads</Link>
                                </Typography><br/>
                                <Typography variant="h5" gutterBottom sx={{fontWeight: 'bold'}}>
                                    Order details
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    Order #{maybeOrder?.id}
                                </Typography>
                                <Typography>
                                    Order placed: {getOrderDate(maybeOrder?.createdAt || '')}
                                </Typography>
                                <List sx={{mt: 5}}>
                                    <ListItem secondaryAction={<></>}>
                                        <Header/>
                                    </ListItem>
                                    <Divider/>
                                    {orderItems.map((orderItem, idx) => (
                                        <Item key={idx} idx={idx} orderItem={orderItem}/>
                                    ))}
                                    <ListItem secondaryAction={<></>}>
                                        <Total orderTotal={maybeOrder?.total}/>
                                    </ListItem>
                                </List>
                                <List sx={{mt: 5}}>
                                    <ListItem secondaryAction={<></>}>
                                        <PaymentMethodHeader/>
                                    </ListItem>
                                    <Divider/>
                                    <PaymentMethodItem paymentMethod={paymentMethod} order={maybeOrder}/>
                                </List>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
            </Grid>
            {notificationCtx.open && <Notification/>}
            <Loading show={loading}/>
        </>
    );
}

export default OrderPlaced;