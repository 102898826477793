import {Step, StepLabel, Stepper} from "@mui/material";
import React from "react";
import {CHECKOUT_STEPS} from "../helpers/globalConst";

const steps = CHECKOUT_STEPS;
function CheckoutStepper({activeStep = 0}) {
    return(
        <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    )
}

export default CheckoutStepper;