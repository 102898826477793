import {GlobalStyles} from "@mui/material";
import background from "../assets/bg-main.jpg";
import React from "react";

export const globalStyles = <GlobalStyles styles={{
    body: {
        backgroundImage: `url(${background})`,
            //backgroundRepeat: 'no-repeat',
            //backgroundSize: 'cover'
    }
}}/>;