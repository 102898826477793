import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import * as React from "react";

function AvatarLockOutlinedIcon() {
    return (
        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <LockOutlinedIcon/>
        </Avatar>
    );
}

export default AvatarLockOutlinedIcon;