import * as React from "react";
import {useContext, useEffect, useRef} from "react";
import {Card, CardContent, Container, Typography} from "@mui/material";
import {useActivateMutation} from "../../services/identityService";
import NotificationContext from "../NotificationContext";
import Notification from "../Notification";
import Loading from "../Loading";
import {useLocation, useNavigate} from "react-router-dom";
import AvatarLockOutlinedIcon from "../../particles/AvatarLockOutlinedIcon";
import {SIGN_IN} from "../../helpers/routes";
import {ACCOUNT_ACTIVATION_LINK_INVALID, ACCOUNT_ACTIVATION_SUCCESS} from "../../helpers/notifications";
import lodash from "lodash";
import {ENV_DEVELOPMENT} from "../../helpers/globalConst";

function ActivateAccountCard() {
    const location = useLocation();
    const navigate = useNavigate();
    const notificationCtx = useContext(NotificationContext);
    const devEnv = useRef(false);
    const [activateCustomer, activateCustomerResult] = useActivateMutation();

    const authToken = lodash.last(location.pathname.split('/'));

    useEffect(() => {
        const isActivateCustomer = () => {
            activateCustomer({authToken: !lodash.isUndefined(authToken) ? authToken : ''}).unwrap()
                .then(
                    () => {
                        navigate(SIGN_IN);
                        notificationCtx.showNotification(ACCOUNT_ACTIVATION_SUCCESS, 'success');
                    }
                )
                .catch(
                    () => {
                        navigate(SIGN_IN);
                        notificationCtx.showNotification(ACCOUNT_ACTIVATION_LINK_INVALID, 'error');
                    }
                );
            if (process.env.NODE_ENV === ENV_DEVELOPMENT) devEnv.current = true;
        }

        if (!devEnv.current) isActivateCustomer();
    }, [authToken, navigate, notificationCtx, activateCustomer])

    return (
        <>
            <Container sx={{pt: 2, pb: 3}} maxWidth='xs'>
                <Card variant="outlined">
                    <CardContent>
                        <Container component='div' sx={{display: 'flex', justifyContent: 'center'}}>
                            <AvatarLockOutlinedIcon/>
                        </Container>
                        <Typography component='h1' variant='h5' sx={{textAlign: 'center'}}>
                            Activating account...
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
            {notificationCtx.open && <Notification/>}
            <Loading show={activateCustomerResult.isLoading}/>
        </>
    );
}

export default ActivateAccountCard;