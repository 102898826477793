import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../store';
import {LoggedInIdentity} from "../../helpers/interfaces";

interface IdentityState {
    loggedInIdentity: LoggedInIdentity
}

const initialState: IdentityState = {
    loggedInIdentity: {}
};

export const identitySlice = createSlice({
    name: 'identity',
    initialState,
    reducers: {
        saveIdentity: (state, action: PayloadAction<LoggedInIdentity>) => {
            state.loggedInIdentity = action.payload;
        },
        removeIdentity: (state) => {
            state.loggedInIdentity = {}
        }
    }
});

export const {saveIdentity, removeIdentity} = identitySlice.actions;
export const getLoggedInIdentity = (state: RootState) => state.root.identityReducer.loggedInIdentity;

export const identityReducer = identitySlice.reducer;