import React, {Fragment} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Divider, FormControl, IconButton, Link, ListItem, Select} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import lodash from "lodash";
import MenuItem from "@mui/material/MenuItem";
import {CartItem} from "../../helpers/interfaces";
import {PRODUCT_IMAGE_BASE_URL} from "../../helpers/apiRoutes";
import {Link as RouterLink} from "react-router-dom";
import {PRODUCT} from "../../helpers/routes";

interface ItemProps {
    idx: number,
    productId?: number,
    cartItem: CartItem,
    description?: string,
    imageLocation?: string,
    handleRemoveCartItem: (cartItem: CartItem) => void,
    handleUpdateCartItemQty: (cartItem: CartItem, number: number) => void,
    productPrice: number
}

function Item(itemPreps: ItemProps) {
    const {idx, cartItem, description, imageLocation, handleRemoveCartItem,
        handleUpdateCartItemQty, productPrice, productId} = itemPreps;
    return (
        <Fragment key={idx}><ListItem secondaryAction={
            <IconButton edge="end" aria-label="delete"
                        onClick={() => handleRemoveCartItem(cartItem)}>
                <DeleteIcon/>
            </IconButton>
        }>
            <Grid lg={6} md={6} sm={6} xs={6}>
                <Link color="inherit" underline="none" component={RouterLink} to={`${PRODUCT}/${productId}`}>
                <img src={`${PRODUCT_IMAGE_BASE_URL + imageLocation}`} alt={description}
                     width={50} style={{float: 'left', marginRight: 10}}/><ListItemText
                primary={description} /></Link>
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                <FormControl variant="standard" sx={{m: 1, minWidth: 50}}>
                    <Select
                        labelId="cart-item-qty"
                        id="cart-item-qty-select"
                        value={cartItem.qty}
                        onChange={(e) => {
                            handleUpdateCartItemQty(cartItem, Number(e.target.value))
                        }}
                    >
                        {lodash.range(1, 20).map(i => {
                            return <MenuItem key={i} value={i}>{i}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                ${productPrice.toFixed(2)}
            </Grid>
            <Grid lg={2} md={2} sm={2} xs={2}>
                ${(productPrice * (cartItem.qty || 0)).toFixed(2)}
            </Grid>
        </ListItem>
            <Divider/>
        </Fragment>
    )
}

export default Item;