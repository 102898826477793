import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import {CUSTOMER_SUPPORT_EMAIL} from "../../helpers/globalConst";
import {useContext} from "react";
import NotificationContext from "../NotificationContext";
import {useRequestActivationEmailMutation} from "../../services/identityService";
import Loading from "../Loading";
import {CUSTOMER_ACTIVATION_EMAIL_FAILED, CUSTOMER_ACTIVATION_EMAIL_SENT} from "../../helpers/notifications";

function ActivationEmailFailedDialog({show = false, email = '', handleClose = () => {}}) {
    const notificationCtx = useContext(NotificationContext);
    const [requestActivationEmail, {isLoading}] = useRequestActivationEmailMutation();
    const onSubmit = (email: string) => {
        requestActivationEmail({email: email})
            .unwrap()
            .then(
                () => {
                    notificationCtx.showNotification(CUSTOMER_ACTIVATION_EMAIL_SENT, 'success');
                    handleClose();
                }
            )
            .catch(
                () => {
                    notificationCtx.showNotification(CUSTOMER_ACTIVATION_EMAIL_FAILED, 'error');
                }
            );

    }

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"We are sorry!"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Sending the activation email to ${email} failed.`}
                    <br/><br/>
                    To resend the activation email click <Link component='button' variant="button" onClick={() => onSubmit(email)}>here</Link>
                    <br/><br/>If the problem persists please contact Customer support:
                    <br/><Link href={`mailto:${CUSTOMER_SUPPORT_EMAIL}`}>{CUSTOMER_SUPPORT_EMAIL}</Link>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
            <Loading show={isLoading}/>
        </Dialog>
    );
}

export default ActivationEmailFailedDialog;